import { useState } from "react";
import {ColumnDef, createColumnHelper} from "@tanstack/react-table";
import {NavLink} from "react-router-dom";
import Table from "components/Table/Table";
import Input from "components/Input/Input";

interface TitleTableData {
	articleUID: string;
	articleTitle: string;
	keyword: string;
	keywordHash: string;
	keywordTraffic: number;
	wordCount: number;
	isProcessing: boolean;
	isGenerated: boolean;
	isPosted: boolean;
	isFailed: boolean;
	isArchived: boolean;
	postedTo: string,
}

export default function Titles(props: {results: Array<TitleTableData>}) {
	// ---------------------- NON STATE CONSTANTS ----------------------
	const pageSizes = [10, 50, 150, 250, 500];

	// ---------------------- STATES ----------------------
	const [searchBoxFilter, setSearchBoxFilter] = useState('');

	// ---------------------- TABLE COLUMN DEFS ----------------------
	const columnHelper = createColumnHelper<TitleTableData>();
	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: TitleTableData) => row.articleTitle, {
			id: 'articleTitle',
			header: "Article Title",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: TitleTableData) => row.wordCount, {
			id: 'wordCount',
			header: "Words",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		
		columnHelper.display({
			id: 'action',
			header: "Action",
			cell: props => {
				if (props.row.original.isGenerated) {
					return (
						<NavLink to={`/view-article/${props.row.original.articleUID}/`} className={`button is-small more-rounded-borders is-success custom-btn`}>
							View Article
						</NavLink>
					);
				}
				else{
						let btn_color = 'primary'
	
						return (
							<button type="button" className={`button is-small more-rounded-borders is-success custom-btn`} style={btn_color === 'info' ? { backgroundColor: 'yellow', color: 'black', cursor: "default" } : { cursor: "default" }} disabled>View Article</button>
						);
				}
			},
		}),
		
	]

	// ==============================================================
	// ---------------------- MAIN RENDER CODE ----------------------
	// ==============================================================
	return (
		<div className={"all-users-container"}>
			<div className={"table-controls"}>
				{/* -------------------------- SEARCH BOX -------------------------- */}
				<Input type={"text"}
							 className={"table-searchbox"}
							 placeholder={"Search for Titles..."}
							 value={searchBoxFilter ?? ''}
							 onChange={(e) => {
								 setSearchBoxFilter(String(e.target.value));
							 }}/>
			</div>
			<Table tableContentName={"Titles"}
						 tableData={props.results}
						 columnDefs={columnDefs}
						 pageSizes={pageSizes}
						 initialPageSize={pageSizes[0]}
						 noDataText={"No Data Found"}
						 searchBoxValue={searchBoxFilter}/>
		</div>
	)
}
