import "./TextArea.scss";
import { TextareaHTMLAttributes } from "react";

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	className?: string
	id?: string
	name?: string
	placeholder?: string
	value?: string
	onChange?: (event: any) => void
}

export default function TextArea(props: TextAreaProps) {
	return <textarea
		className={"textarea " + (props.className || "")}
		name={props.name}
		id={props.id}
		placeholder={props.placeholder}
		value={props.value}
		onChange={props.onChange}
	/>
}