import axios, {AxiosError} from "axios";

const ACCESS_TOKEN_ID = "ab_act";
const REFRESH_TOKEN_ID = "ab_rft";

interface RenewData {
	access: string
	refresh: string
}

/**
 * Removes both access and refresh token from localstorage.
 */
export function clearTokens() {
	localStorage.removeItem(ACCESS_TOKEN_ID);
	localStorage.removeItem(REFRESH_TOKEN_ID);
}

/**
 * Saves access/refresh token to localstorage.
 *
 * @param type - "access" or "refresh"
 * @param tokenValue - token value to save
 */
export function saveToken(type: "access" | "refresh", tokenValue: string) {
	if (type === "access") {
		localStorage.setItem(ACCESS_TOKEN_ID, tokenValue);
	} else {
		localStorage.setItem(REFRESH_TOKEN_ID, tokenValue);
	}
}

/**
 * Fetches Access and Refresh tokens from local storage. Values are set to null in case they are missing.
 */
export function retrieveTokens(): { access: string | null, refresh: string | null } {
	return {
		'access': localStorage.getItem(ACCESS_TOKEN_ID),
		'refresh': localStorage.getItem(REFRESH_TOKEN_ID),
	}
}

/**
 * Renews access token using refresh token. Since we have rotating refresh tokens enabled, server will also provide a
 * new refresh token for future use. Returns true if renewal was successful.
 *
 * @returns boolean
 */
export async function renewAccessToken(): Promise<boolean> {
	let tokens = retrieveTokens();

	if (tokens['refresh']) {
		try {
			let response = await axios({
				method: "post",
				url: process.env.REACT_APP_DRF_DOMAIN + "/api/frontend/token/refresh/",
				data: {
					'refresh': tokens['refresh']
				},
				responseType: 'json'
			});

			// axios treats any status code outside 2xx as error. So no need to check status code over here
			// store new refresh and access token
			saveToken('access', (response.data as RenewData)['access']);
			saveToken('refresh', (response.data as RenewData)['refresh']);
			return true

		} catch (err) {
			const axiosError = err as AxiosError;
			if (axiosError.response?.status === 401) {
				// Refresh token expired. Need to delete existing tokens so that user is redirected to login.
				clearTokens();
				return false
			} else {
				console.error("token renewal failed with status code " + axiosError.response?.status);
				return false
			}
		}
	} else {
		// No refresh token present in localstorage
		return false
	}
}
