import {
	ColumnDef,
	FilterFn,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel, RowData, RowModel,
	useReactTable
} from "@tanstack/react-table";
import React, {HTMLProps, useEffect, useMemo, useState} from "react";
import DelayedInput from "../DelayedInput/DelayedInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import "./AbunTable.scss";

interface AbunTabelProps {
	tableContentName: string
	tableData: Array<any>
	columnDefs: ColumnDef<any, any>[]
	pageSizes: Array<number>
	initialPageSize: number
	noDataText: string
	searchboxPlaceholderText: string
	rowCheckbox?: boolean
	selectedRowsSetter?: (rowModel: RowModel<RowData>) => void
	buttons?: Array<AbunTableButton>
}

interface AbunTableButton {
	text: string
	type: "primary" | "success" | "warning" | "danger"
	invisible?: boolean
	clickHandler: () => void
}

const stringSearchFilter: FilterFn<any> = (row, columnId, value) => {
	let cellValue: string = (row.getValue(columnId) as string | number).toString().toLowerCase();
	return cellValue.includes(value);
}

export default function AbunTable(props: AbunTabelProps) {
	// --------------------------- STATES ---------------------------
	const [globalFilter, setGlobalFilter] = useState('');
	const [data, setData] = useState(() => [...props.tableData]);
	const columns = useMemo<ColumnDef<any, any>[]>(() => props.columnDefs, [
		props.columnDefs
	])
	const [rowSelection, setRowSelection] = React.useState({});

	useEffect(() => {
		setData([...props.tableData]);
	}, [props.tableData]);

	useEffect(() => {
		// props.selectedRowsSetter()
		if (props.rowCheckbox && props.selectedRowsSetter) {
			props.selectedRowsSetter(table.getSelectedRowModel());
		}
	}, [rowSelection]);

	const table = useReactTable({
		data: data,
		columns: columns,
		getCoreRowModel: getCoreRowModel(),
		state: {
			globalFilter,
			rowSelection,
		},
		globalFilterFn: stringSearchFilter,
		onGlobalFilterChange: setGlobalFilter,
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		initialState: {
			pagination: {
				pageSize: props.initialPageSize
			}
		},
		enableRowSelection: props.rowCheckbox,
		onRowSelectionChange: setRowSelection,
	});

	/**
	 * Returns JSX element with no data text if one of the no data conditions is met. Otherwise returns empty JSX element.
	 * Conditions:
	 * 1. Table data receied through props was empty (ex. no articles in db).
	 * 2. Number of rows in Tanstack Table object is 0 (ex. no filter matches).
	 */
	function getNoDataTextElement() {
		if (props.tableData.length <= 0) {
			return (
				<div className={"abun-table-no-data"}>
					{props.noDataText}
				</div>
			)

		} else if (table.getRowModel().rows.length <= 0) {
			return (
				<div className={"abun-table-no-data"}>
					No data matching this search query :(<br/>
					Clear the searchbox or try using a different search text.
				</div>
			)

		} else {
			return <></>
		}
	}

	function handlePageNumberChange(newPageNum: string) {
		let num: number;

		try {
			// if user provided page number exceeds total pages, cap it to last page number;
			num = parseInt(newPageNum);
			if (num > table.getPageCount()) {
				num = table.getPageCount();
			} else {
				num = Math.max(num, 1)
			}
		} catch (e) {
			num = 1;
		}

		// -1 because page index starts at 0
		table.setPageIndex(num - 1);
	}

	function goToPrevPage() {
		table.setPageIndex(table.getState().pagination.pageIndex - 1);
	}

	function goToNextPage() {
		table.setPageIndex(table.getState().pagination.pageIndex + 1)
	}

	function changePageSize(newSize: string) {
		let newSizeNumber
		try {
			newSizeNumber = parseInt(newSize);
		} catch (e) {
			newSizeNumber = 1
		}
		if (newSizeNumber <= 0) newSizeNumber = 1;
		table.setPageSize(newSizeNumber);
	}

	return (
		<div className={"abun-table-responsive"}>
			<div className={"abun-table-container abun-table-container--fullwidth"}>
				<DelayedInput initialValue={globalFilter ?? ''}
											delayInMilliseconds={500}
											placeholder={props.searchboxPlaceholderText}
											additionalClasses={['abun-table-search-input']}
											resetInput={undefined}
											onChange={value => {
												setGlobalFilter(String(value));
											}}/>
				<div className={"abun-table-button-container"}>
					{props.buttons?.map((button, index) => (
						<button key={index}
										className={`button is-${button.type}`} style={{display: button.invisible ? "none" : "inline-block"}}
										onClick={button.clickHandler}>
							{button.text}
						</button>
					))}
				</div>
				<table className={"table is-fullwidth"}>
					<thead>
					{table.getHeaderGroups().map(headerGroup => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map(header => (
								<th key={header.id}>
									{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
								</th>
							))}
						</tr>
					))}
					</thead>
					<tbody>
					{table.getRowModel().rows.map(row => (
						<tr key={row.id}>
							{row.getVisibleCells().map(cell => (
								<td key={cell.id} align={(cell.column.columnDef.meta as any)?.align}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							))}
						</tr>
					))}
					</tbody>
				</table>
				{getNoDataTextElement()}
				<div className={"abun-table-pagination-controls-container"}>
					<div className={"abun-table-pagination-size-select"}>
						Total <b>{props.tableData.length}</b> {props.tableContentName}&nbsp;&nbsp;|&nbsp;&nbsp;Show&nbsp;
						<div className="select is-small">
							<select defaultValue={props.initialPageSize} onChange={e => changePageSize(e.target.value)}>
								{props.pageSizes.map(size => (
									<option key={size} value={size}>{size}</option>
								))}
							</select>
						</div>
					</div>
					&nbsp;
					per page
					<div className={"abun-table-pagination-page-control"}>
						<button className={"chevron-icons"} disabled={!table.getCanPreviousPage()} onClick={goToPrevPage}>
							<FontAwesomeIcon icon={"chevron-left"}/>
						</button>
						<div className={"page-input-container"}>
							<DelayedInput initialValue={(table.getState().pagination.pageIndex + 1).toString()}
														delayInMilliseconds={500}
														resetInput={undefined}
														onChange={handlePageNumberChange}
														additionalClasses={["page-input"]}/>
							&nbsp;/&nbsp;{table.getPageCount()}
						</div>
						<button className={"chevron-icons"} disabled={!table.getCanNextPage()} onClick={goToNextPage}>
							<FontAwesomeIcon icon={"chevron-right"}/>
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export function IndeterminateCheckbox(
	{indeterminate, className = '', ...rest}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>
) {
	const ref = React.useRef<HTMLInputElement>(null!)
	React.useEffect(() => {
		if (typeof indeterminate === 'boolean') {
			ref.current.indeterminate = !rest.checked && indeterminate
		}
	}, [ref, indeterminate, rest.checked])

	return (
		<input
			type="checkbox"
			ref={ref}
			className={className + ' cursor-pointer'}
			{...rest}
		/>
	)
}
