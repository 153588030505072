import "./PageLoading.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function PageLoading() {
	return (
		<div className={"page-loading-container"}>
			<h3 className={"is-size-3 has-text-centered has-text-weight-bold"}>
				Loading Data...&nbsp;<FontAwesomeIcon icon={"spinner"} spin={true}/>
			</h3>
			<p className={"is-size-5 has-text-centered mt-3"}>This should only take a few seconds</p>
		</div>
	)
}
