import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import routes from "pages/routes";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import {library} from '@fortawesome/fontawesome-svg-core';
import {
	faArrowTrendUp,
	faCircleNotch,
	faCog,
	faDollar,
	faDownload,
	faHome,
	faNoteSticky,
	faSignOut,
	faTools,
	faUserPlus,
	faUsers,
	faSpinner,
	faChevronLeft,
	faChevronRight,
	faUserPen,
	faFloppyDisk,
	faXmark,
	faTrashCan,
	faGlobe,
	faLock,
	faLockOpen,
	faUserShield,
	faSignal,
	faClock,
	faLinkSlash,
	faPlus,
	faFileAlt,
	faExclamationTriangle,
	faMoneyBill1,
} from "@fortawesome/free-solid-svg-icons";
import {faCalendarDays, faCircleXmark} from "@fortawesome/free-regular-svg-icons"
import {faCreativeCommonsNc} from "@fortawesome/free-brands-svg-icons"
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";

library.add(
	faHome, faUsers, faDownload, faNoteSticky, faCog, faTools, faSignOut,
	faDollar, faUserPlus, faArrowTrendUp, faCreativeCommonsNc, faCircleNotch,
	faSpinner, faChevronLeft, faChevronRight, faCalendarDays, faUserPen, faFloppyDisk,
	faXmark, faTrashCan, faGlobe, faLock, faUserShield, faSignal, faClock,
	faCircleXmark, faLockOpen, faLinkSlash , faPlus, faFileAlt, faExclamationTriangle, faMoneyBill1
);

const router = createBrowserRouter(routes);
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<RouterProvider router={router}/>
		</QueryClientProvider>
	</React.StrictMode>
);

// Use this to toggle dark mode
const darkMode = localStorage.getItem('darkmode');
if (darkMode) {
	document.body.classList.add('dark');
}

// Use this to toggle dark mode according to system preferences
const followSysTheme = localStorage.getItem("followSysTheme");
if (followSysTheme) {
	window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
		const newColorScheme = e.matches ? 'dark' : 'light';
		document.body.classList.toggle('dark', newColorScheme === 'dark');
	});
	if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
		document.body.classList.add('dark');
	} else {
		document.body.classList.remove('dark');
	}
}
