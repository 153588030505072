import {useRef, useState} from "react";
import Card from "components/Card/Card"
import abunLogo from "assets/images/branding/abun_blue_text_logo.png"
import Input from "components/Input/Input";
import Button from "components/Button/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {Link, useNavigate} from "react-router-dom";
import Alert from "components/Alert/Alert";
import {useMutation} from "@tanstack/react-query";
import {adminSignup} from "../../../utils/api";
import {saveToken} from "../../../utils/jwt";
import {AxiosError} from "axios";
import {pageURL} from "../../routes";

interface SignupResponse {
	access_token: string
	refresh_token: string
}

export default function Signup() {

	// ----------------------- STATES -----------------------
	const [adminUsername, setAdminUsername] = useState("");
	const [adminEmail, setAdminEmail] = useState("");
	const [adminPassword, setAdminPassword] = useState("");
	const [adminSecret, setAdminSecret] = useState("");

	// ----------------------- REFS -----------------------
	const errorAlert = useRef<any>(null);
	const navigate = useNavigate();

	// ----------------------- MUTATIONS -----------------------
	const signupMutation = useMutation({
		mutationKey: ['adminsignup', adminUsername, adminEmail],
		mutationFn: adminSignup,
		gcTime: 0,
		onSuccess: (data) => {
			let signupResponse: SignupResponse = data['data'];
			saveToken('access', signupResponse.access_token);
			saveToken('refresh', signupResponse.refresh_token);
			return navigate(pageURL['dashboard']);
		},
		onError: (error: AxiosError) => {
			if (error.response?.status === 403) {
				errorAlert.current?.show("Please enter the correct Admin Secret passcode");

			} else {
				errorAlert.current?.show("Oops! Something went wrong. Please try again in some time.");
			}
		},
	})

	// ----------------------- FUNCTIONS -----------------------
	function signupHandler() {
		if (adminUsername && adminEmail && adminPassword && adminSecret) {
			signupMutation.mutate(
				{
					username: adminUsername,
					email: adminEmail,
					password: adminPassword,
					secret: adminSecret
				}
			);

		} else {
			errorAlert.current?.show("Please fill in all the required fields");
		}
	}

	// ----------------------- RENDER CODE -----------------------
	return (
		<>
			<Card>
				<div className={"auth-card-content"}>
					<img src={abunLogo} alt={"Abun Logo"} className={"auth-card-logo"}/>
					<h3 className={`is-size-3 has-text-centered has-text-primary secondary-font`}>
						Admin Account Signup
					</h3>
					{/* ------------------------- DISPLAY NAME ------------------------- */}
					<div className={"field"}>
						<label htmlFor="admin-username" className={"label"}>Username:</label>
						<div className={"control"}>
							<Input type={"text"}
										 placeholder={"John Doe"}
										 id={"admin-username"}
										 value={adminUsername}
										 onChange={(e) => setAdminUsername(e.target.value)}/>
						</div>
					</div>
					{/* ------------------------- EMAIL ID ------------------------- */}
					<div className={"field"}>
						<label htmlFor="admin-email" className={"label"}>Email ID:</label>
						<div className={"control"}>
							<Input type={"email"}
										 placeholder={"john@example.com"}
										 id={"admin-email"}
										 value={adminEmail}
										 onChange={(e) => setAdminEmail(e.target.value)}/>
						</div>
					</div>
					{/* ------------------------- PASSWORD ------------------------- */}
					<div className={"field"}>
						<label htmlFor="admin-password" className={"label"}>Password:</label>
						<div className={"control"}>
							<Input type={"password"}
										 placeholder={"Minimum 6 characters"}
										 id={"admin-password"}
										 value={adminPassword}
										 onChange={(e) => setAdminPassword(e.target.value)}/>
						</div>
					</div>
					{/* ------------------------- SECRET ------------------------- */}
					<div className={"field"}>
						<label htmlFor="admin-secret" className={"label"}>Admin Secret:</label>
						<div className={"control"}>
							<Input type={"password"}
										 id={"admin-secret"}
										 value={adminSecret}
										 onChange={(e) => setAdminSecret(e.target.value)}/>
						</div>
					</div>
					<Button color={"success"} className={"mt-6 is-flex ml-auto mr-auto"} onClick={() => {
						signupHandler();
					}}>
						{signupMutation.isPending ? <>SIGNING UP...&nbsp;<FontAwesomeIcon icon={faSpinner}/></> : "CREATE ACCOUNT"}
					</Button>
					<div className={"block"}>
						<p className={"has-text-centered mt-5"}>Already Have an Account? <Link to={"/login"}>Log In</Link></p>
					</div>
				</div>
			</Card>
			<Alert type={"danger"} ref={errorAlert}/>
		</>
	)
}
