import {CSSProperties, ReactNode} from "react";

import "./AbunButton.scss";

interface AbunButtonProps {
	children: ReactNode
	type: "primary" | "success" | "danger" | "warning" | "light" | "none"
	className?: string
	style?: CSSProperties
	disabled?: boolean
	clickHandler: () => void
}

export default function AbunButton(props: AbunButtonProps) {
	if (props.type === "none") {
		return (
			<button className={`transparent-button ${props.className ? props.className : ""}`}
							style={props.style}
							disabled={props.disabled}
							onClick={props.clickHandler}>
				{props.children}
			</button>
		)
	} else {
		return (
			<button className={`button is-${props.type} ${props.className}`}
							style={props.style}
							disabled={props.disabled}
							onClick={props.clickHandler}>
				{props.children}
			</button>
		)
	}
}
