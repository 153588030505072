import "./blockedDomains.scss";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
    getBlockDomains,
    addBlockDomains,
    removeBlockDomains,
    retryFn,
} from "utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef, createColumnHelper, RowData, RowModel } from "@tanstack/react-table";
import AbunTable, { IndeterminateCheckbox } from "components/AbunTable/AbunTable";
import Modal from "../../components/Modal/Modal";
import TextArea from "../../components/TextArea/TextArea";
import Alert from "../../components/Alert/Alert";
import CSVUpload from "components/CSVUpload/CSVUpload";

interface BlockedDomains {
    websiteDomain: string
}

let err_message = "";

type Tab = 'textarea' | 'csv';

// A simple page that shows a list of block domains, allows adding new ones one at a time and deleting existing ones in bulk.
export default function BlockDomains() {
    // ---------------------- NON STATE CONSTANTS ----------------------
    const pageSizes = [15, 50, 100];

    // -------------------------- STATES --------------------------
    const [blockedDomains, setBlockedDomains] = useState<Array<BlockedDomains>>([]);
    const [selectedRows, setSelectedRows] = useState<RowModel<RowData>>();
    const [AddDomainsToBlockModal, setDomainModal] = useState(false);
    const [RemoveActionConfirmationModal, setRemoveActionConfirmationModal] = useState(false);
    const [tab, setTab] = useState<Tab>('textarea');
    const [DomainSearch, setDomainSearch] = useState("");

    // -------------------------- QUERIES --------------------------
    const {
        isFetching,
        isError,
        data,
        refetch,
    } = useQuery({
        queryKey: ['getBlockDomains'],
        queryFn: getBlockDomains,
        refetchOnWindowFocus: false,
        retry: retryFn
    });

    // -------------------------- MUTATIONS --------------------------
    const addBlockDomainMutation = useMutation({
        mutationKey: ['addBlockDomains'],
        mutationFn: addBlockDomains,
        gcTime: 0,
        onSuccess: (response) => {
            if (!err_message) {
                successAlert.current?.show("Domain(s) have been blocked successfully!");
                err_message = "";
            } else {
                errorAlert.current?.show(err_message);
                err_message = "";
            }
            setDomainSearch("");
            setBlockedDomains(response.data.all_domains.reverse());
        },
        onError: (error) => {
            errorAlert.current?.show(`Failed to save domain: ${error}`);
            setDomainSearch("");
        },
    });

    const removeBlockDomainsMutation = useMutation({
        mutationKey: ['removeBlockDomains'],
        mutationFn: removeBlockDomains,
        gcTime: 0,
        onSuccess: (response) => {
            successAlert.current?.show("Domain(s) have been removed successfully!");
            setDomainSearch("");
            setBlockedDomains(response.data.all_domains.reverse());
        },
        onError: (error) => {
            errorAlert.current?.show(`Failed to remove domains: ${error}`);
            setDomainSearch("");
        },
    })

    // ---------------------- EFFECTS ----------------------
    useEffect(() => {
        if (data) {
            setBlockedDomains(data['data'].reverse());
        }
    }, [data]);

    // --------------------- REFS ---------------------
    const errorAlert = useRef<any>(null);
    const successAlert = useRef<any>(null);

    // ---------------------- FUNCTIONS ----------------------
    function selectedRowsSetter(rowModel: RowModel<RowData>) {
        setSelectedRows(rowModel);
    }

    function addDomainsDone() {
        setDomainModal(false);
        successAlert.current?.show("Domain(s) have been blocked successfully!");

        setTimeout(() => {
            refetch().then(() => {
            });
        }, 3000)
    }

    function onAddDomainsToBlock(UserInputs: string) {
        // split the input by comma or newline
        const allDomains = UserInputs?.split(",").join("\n").split("\n");
        let validDomains: Array<string> = [];
        allDomains?.forEach(input => {
            if (input.trim()) {
                validDomains.push(input.trim());
            }
        });
        if (validDomains.length < 1) {
            errorAlert.current?.show("Please enter at least one valid domain. ");
            return;
        } else if (validDomains.length > 100000) {
            errorAlert.current?.show("Max 100k domains should be submitted at once.");
            return;
        }else {
            setDomainModal(false);
            addBlockDomainMutation.mutate(validDomains);
        }
    }

    function onRemoveBlockDomains(websiteDomains: Array<string>) {
        if (websiteDomains.length < 1) {
            errorAlert.current?.show("Please select at least one domain to remove.");
            return;
        }
        // on remove multiple selected domains from the list
        removeBlockDomainsMutation.mutate(websiteDomains);
    }

    // ---------------------- TABLE COLUMN DEFS ----------------------
    const columnHelper = createColumnHelper<BlockedDomains>();
    const columnDefs: ColumnDef<any, any>[] = [
        columnHelper.accessor((row: BlockedDomains) => row.websiteDomain, {
            id: 'checkbox',
            header: ({ table }) => (
                <IndeterminateCheckbox
                    {...{
                        checked: table.getIsAllRowsSelected(),
                        indeterminate: table.getIsSomeRowsSelected(),
                        onChange: table.getToggleAllRowsSelectedHandler(),
                    }}
                />
            ),
            cell: ({ row }) => (
                <IndeterminateCheckbox
                    {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }}
                    name={"blockDomainSelection"}
                    value={row.original.websiteDomain}
                />
            ),
            enableGlobalFilter: true,
        }),
        columnHelper.accessor((row: BlockedDomains) => row.websiteDomain, {
            id: 'websiteDomain',
            header: "Website Domain",
            cell: info => info.getValue(),
            enableGlobalFilter: true,
        }),
        columnHelper.display({
            id: 'removeBlockDomain',
            header: () => (<div style={{ textAlign: "center" }}>Remove Domain</div>),
            cell: props => {
                return (
                    <button className={"button is-danger is-small is-outlined"}
                        onClick={() => {
                            props.row.toggleSelected();
                            setRemoveActionConfirmationModal(true);
                        }
                        }>
                        Remove&nbsp;&nbsp;
                    </button>
                );
            },
            enableGlobalFilter: false,
            meta: {
                align: 'center'
            }
        }),
    ]
    // ============================================================
    // --------------------- MAIN RENDER CODE ---------------------
    // ============================================================
    if (isFetching) {
        return (
            <p style={{ textAlign: "center", fontSize: "1.3rem" }} className="mt-5">
                Loading Data...<FontAwesomeIcon icon={"spinner"} className={"ml-5"} />
            </p>
        )
    } else if (isError) {
        return (
            <section className="section">
                <div className="container">
                    <div className="box">
                        <h1 className="title has-text-centered">Blocked Domains</h1>
                        <p className="has-text-centered is-size-5">
                            Failed to load data. Please try again later.
                        </p>
                    </div>
                </div>
            </section>
        );
    } else {
        return (
            <section className="section">
                <div className="container">
                    <div className="box">
                        {/* ******************* Add Block Domain Modal ******************* */}
                        <Modal active={AddDomainsToBlockModal}
                            headerText={"Add Domains to Block"}
                            closeable={true}
                            hideModal={() => {
                                setDomainModal(false);
                                setDomainSearch("");
                            }
                            }>
                            <div className="tabs">
                                <ul>
                                    <li className={tab === 'textarea' ? "is-active" : ""} onClick={() => setTab('textarea')}>
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        <a>Enter Manually</a>
                                    </li>
                                    <li className={tab === 'csv' ? "is-active" : ""} onClick={() => setTab('csv')}>
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        <a>Use CSV File</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content">
                                {tab === 'textarea' ?
                                    <TextArea
                                        className={"textarea"}
                                        id={"addBlockDomains"}
                                        name={"addBlockDomains"}
                                        placeholder={"Add domains to block (separated by commas or newlines)"}
                                        value={DomainSearch}
                                        onChange={(event) => setDomainSearch(event.target.value)}
                                    />
                                    :
                                    <CSVUpload csvUploadDone={addDomainsDone} title="Choose column with domain data" name="domains"
                                        mutation={addBlockDomainMutation} />}
                            </div>
                            <button className={"button is-primary is-fullwidth mt-4"}
                                onClick={() => onAddDomainsToBlock(DomainSearch)}
                                style={{ display: tab === 'textarea' ? 'block' : 'none' }}>
                                Add Domains to Block
                            </button>
                        </Modal>

                        {/* ******************* Remove confirmation Modal ******************* */}
                        <Modal active={RemoveActionConfirmationModal}
                            headerText={""}
                            closeable={true}
                            hideModal={() => setRemoveActionConfirmationModal(false)}>
                            <h1 className="title has-text-centered">Are you sure you want to remove the selected domain(s)?</h1>
                            <div className="buttons is-centered">
                                <button className={"button is-danger is-outlined"}
                                    onClick={() => {
                                        setRemoveActionConfirmationModal(false);
                                        if (selectedRows && selectedRows.rows.length > 0) {
                                            const selectedDomains = selectedRows.rows.map(row => {
                                                return (row.original as BlockedDomains).websiteDomain
                                            });
                                            onRemoveBlockDomains(selectedDomains);
                                            selectedRows.rows.forEach(row => {
                                                row.toggleSelected();
                                            });
                                        }
                                    }}>
                                    Remove Selected
                                </button>
                                <button className={"button is-primary is-outlined"}
                                    onClick={() => setRemoveActionConfirmationModal(false)}>
                                    Cancel
                                </button>
                            </div>
                        </Modal>

                        <AbunTable tableContentName={"Blocked Domains"}
                            tableData={
                                blockedDomains.map((domain) => {
                                    return {
                                        websiteDomain: domain
                                    }
                                })
                            }
                            columnDefs={columnDefs}
                            pageSizes={pageSizes}
                            initialPageSize={pageSizes[0]}
                            noDataText={
                                blockedDomains.length === 0 ?
                                    "No blocked domains available."
                                    : "No domains found."
                            }
                            searchboxPlaceholderText={"Search domains..."}
                            rowCheckbox={true}
                            selectedRowsSetter={selectedRowsSetter}
                            buttons={[
                                {
                                    text: "Add Domains to Block",
                                    type: "primary",
                                    clickHandler: () => setDomainModal(true)
                                },
                                {
                                    text: "Remove All Selected",
                                    type: "danger",
                                    clickHandler: () => setRemoveActionConfirmationModal(true),
                                    invisible: !selectedRows || selectedRows.rows.length < 1
                                },
                                {
                                    text: "Refresh",
                                    type: "primary",
                                    clickHandler: () => refetch()
                                }
                            ]} />
                    </div>
                </div>
                <Alert type={"danger"} ref={errorAlert} />
                <Alert type={"success"} ref={successAlert} />
            </section>
        );
    }
}
