import "./Modal.scss";

import {ReactNode, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface ModalProps {
	active: boolean
	headerText: string
	children: ReactNode
	closeable: boolean
	hideModal: () => void;
}

export default function Modal(props: ModalProps) {
	// ------------------- STATES -------------------
	const [modalActive, setModalActive] = useState(props.active);

	useEffect(() => {
		setModalActive(props.active);
	}, [props.active]);

	return (
		<div className={modalActive ? "modal is-active" : "modal"}>
			<div className="modal-background"></div>
			<div className="modal-content">
				<div className="card modal-card">
					{props.closeable ?
						<button className={"custom-modal-close"} onClick={() => {
							props.hideModal();
						}}>
							<FontAwesomeIcon icon={["far", "circle-xmark"]}/>
						</button> :
						<></>}
					<div className={props.headerText !== "" ? "card-header" : "card-header is-hidden"}>
						<div className="card-header-title is-justify-content-center">
							<p>{props.headerText}</p>
						</div>
					</div>
					<div className="card-content">
						<div className="content">
							{props.children}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
