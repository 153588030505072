import "./PageLoadingError.scss";

interface PageLoadingErrorProps {
	message?: string
}

export default function PageLoadingError(props: PageLoadingErrorProps) {
	return (
		<div className={"page-loading-error-container"}>
			<h3 className={"is-size-3 has-text-centered has-text-weight-bold"}>
				Oops! Something went wrong :(
			</h3>
			<p className={"is-size-5 has-text-centered mt-2"}>Please try reloading this page again. If the issue persists, let me know.</p>
			{props.message && <p className={"is-size-5 has-text-centered has-text-danger mt-5"}>
				ERROR: {props.message}
			</p>}
		</div>
	)
}
