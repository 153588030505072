import "./Alert.scss";
import {forwardRef, useImperativeHandle, useState} from "react";

interface AlertProps {
	type: "primary" | "success" | "warning" | "danger" | "info"
	light?: boolean
}

export default forwardRef(function Alert(props: AlertProps, ref) {
	// --------------------- STATES ---------------------
	const [message, setMessage] = useState("");

	// --------------------- FUNCTIONS ---------------------
	function show(successMessage: string) {
		setMessage(successMessage);
	}
	function close() {
		setMessage('');
	}

	useImperativeHandle(ref, () => {
		return {
			show,
			close,
		}
	}, []);

	// --------------------- RENDER CODE ---------------------
	if (message) {
		return (
			<div className={`abun-admin-alert notification is-${props.type} ${props.light ? "is-light" : ""}`.trim()}>
				<button className="delete" onClick={close}></button>
				{message}
			</div>
		)
	} else {
		return null;
	}
});
