import "./Dashboard.scss";

import { useEffect, useState } from "react";
import Card from "components/Card/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import PageLoading from "../../components/PageLoading/PageLoading";
import PageLoadingError from "../../components/PageLoadingError/PageLoadingError";
import { getMiniToolsData, getDashboardData, retryFn } from "../../utils/api";

interface Stats {
	total_signups: number | null
	total_paid_customer: number | null
	total_free_customers: number | null
	today_signup: number | null
	today_paid_users: number | null
	articles_generated_today: number | null
	articles_generated_in_last_7_days: number | null
	all_time_articles_generated: number | null
	failed_articles_today: number | null
	failed_articles_in_last_7_days: number | null
	all_time_failed_articles: number | null
	all_time_openai_article_cost: number | null
	openai_article_cost_today: number | null
	openai_article_cost_last_7_days: number | null
	gpt4_usage_cost_all_time: number | null
	gpt4_usage_cost_today: number | null
	gpt4_usage_cost_last_7_days: number | null
	article_feedback_stats: {
		total_feedbacks: number | null
		positive_feedbacks: number | null
		negative_feedbacks: number | null
	}
}

interface MiniToolStat {
	tools: Array<string> | []
	tool_usage: Object | {}
}

export default function Dashboard() {
	// --------------------- STATES ---------------------
	const [adminStats, setAdminStats] = useState<Stats>({
		total_signups: null,
		total_paid_customer: null,
		total_free_customers: null,
		today_signup: null,
		today_paid_users: null,
		articles_generated_today: null,
		articles_generated_in_last_7_days: null,
		all_time_articles_generated: null,
		failed_articles_today: null,
		failed_articles_in_last_7_days: null,
		all_time_failed_articles: null,
		all_time_openai_article_cost: null,
		openai_article_cost_today: 0,
		openai_article_cost_last_7_days: 0,
		gpt4_usage_cost_all_time: 0,
		gpt4_usage_cost_today: 0,
		gpt4_usage_cost_last_7_days: 0,
		article_feedback_stats: {
			total_feedbacks: 0,
			positive_feedbacks: 0,
			negative_feedbacks: 0
		}
	});

	const [toolStats, setToolStats] = useState<MiniToolStat>({
		tools: [],
		tool_usage: {}
	});

	// --------------------- QUERIES ---------------------
	const { isLoading, isError, data } = useQuery({
		queryKey: ['dashboardData'],
		queryFn: getDashboardData,
		refetchOnWindowFocus: false,
		retry: retryFn,
	});

	const { isLoading: miniToolStatIsLoading, isError: miniToolStatIsError, data: miniToolStatData } = useQuery({
		queryKey: ['miniToolsData'],
		queryFn: getMiniToolsData,
		refetchOnWindowFocus: false,
		retry: retryFn,
	})

	// --------------------- EFFECTS ---------------------
	useEffect(() => {
		if (data) setAdminStats(data['data']);
	}, [data]);

	useEffect(() => {
		if (miniToolStatData) setToolStats(miniToolStatData['data']);
	}, [miniToolStatData]);

	// ============================================================
	// --------------------- MAIN RENDER CODE ---------------------
	// ============================================================
	if (isLoading) return <PageLoading/>

	if (isError) return <PageLoadingError/>

	return (
		<>
			<div className={"admin-stats-container"}>
				<Card className={"mb-4"}>
					<div className={"is-flex"}>
						<div>
							<FontAwesomeIcon icon={"users"} className={"mr-4 has-text-primary"} size={'lg'} />
						</div>
						<h5 className={`is-size-5 secondary-font`}>Total Users</h5>
					</div>
					<p className={"is-size-4"}>{adminStats.total_signups}</p>
				</Card>
				<Card className={"mb-4"}>
					<div className={"is-flex"}>
						<FontAwesomeIcon icon={"dollar"} className={"mr-4 has-text-success"} size={'lg'} />
						<h5 className={`is-size-5 secondary-font`}>Paid Users</h5>
					</div>
					<p className={"is-size-4"}>{adminStats.total_paid_customer}</p>
				</Card>
				<Card className={"mb-4"}>
					<div className={"is-flex"}>
						<FontAwesomeIcon icon={['fab', 'creative-commons-nc']} className={"mr-4 has-text-warning"} size={'xl'} />
						<h5 className={`is-size-5 secondary-font`}>Free Users</h5>
					</div>
					<p className={"is-size-4"}>{adminStats.total_free_customers}</p>
				</Card>
				<Card className={"mb-4"}>
					<div className={"is-flex"}>
						<FontAwesomeIcon icon={"user-plus"} className={"mr-4 has-text-primary"} size={'lg'} />
						<h5 className={`is-size-5 secondary-font`}>New Signups Today</h5>
					</div>
					<p className={"is-size-4"}>{adminStats.today_signup}</p>
				</Card>
				<Card className={"mb-4"}>
					<div className={"is-flex"}>
						<FontAwesomeIcon icon={"arrow-trend-up"} className={"mr-4 has-text-success"} size={'lg'} />
						<h5 className={`is-size-5 secondary-font`}>Paid Users Today</h5>
					</div>
					<p className={"is-size-4"}>{adminStats.today_paid_users}</p>
				</Card>
				<Card className={"mb-4"}>
						<div className={"is-flex"}>
						<FontAwesomeIcon icon={"file-alt"} className={"mr-4 has-text-primary"} size={'lg'} />
							<h5 className={`is-size-5 secondary-font`}>Articles</h5>
						</div>
						<p className={"is-size-6 has-text-primary"}>Today's Articles: {adminStats.articles_generated_today}</p>
						<p className={"is-size-6 has-text-primary"}>Last 7 Days' Articles: {adminStats.articles_generated_in_last_7_days}</p>
						<p className={"is-size-6 has-text-primary"}>Total Articles: {adminStats.all_time_articles_generated}</p>
				</Card>
				<Card className={"mb-4"}>
						<div className={"is-flex"}>
						<FontAwesomeIcon icon={"file-alt"} className={"mr-4 has-text-primary"} size={'lg'} />
							<h5 className={`is-size-5 secondary-font`}>Articles Failed</h5>
						</div>
						<p className={"is-size-6 has-text-primary"}>Failed Today: {adminStats.failed_articles_today}</p>
						<p className={"is-size-6 has-text-primary"}>Failed in Last 7 Days: {adminStats.failed_articles_in_last_7_days}</p>
						<p className={"is-size-6 has-text-primary"}>Total Failed: {adminStats.all_time_failed_articles}</p>
				</Card>
				<Card className={"mb-4"}>
						<div className={"is-flex"}>
						<FontAwesomeIcon icon={"money-bill-1"} className={"mr-4 has-text-success"} size={'lg'} />
							<h5 className={`is-size-5 secondary-font`}>Article Cost</h5>
						</div>
						<p className={"is-size-6 has-text-primary"}>Today's: ${adminStats.openai_article_cost_today?.toFixed(7)}</p>
						<p className={"is-size-6 has-text-primary"}>Last 7 Days: ${adminStats.openai_article_cost_last_7_days?.toFixed(7)}</p>
						<p className={"is-size-6 has-text-primary"}>All time: ${adminStats.all_time_openai_article_cost?.toFixed(7)}</p>
				</Card>
				<Card className={"mb-4"}>
						<div className={"is-flex"}>
						<FontAwesomeIcon icon={"money-bill-1"} className={"mr-4 has-text-success"} size={'lg'} />
							<h5 className={`is-size-5 secondary-font`}>Titles Gen. Cost</h5>
						</div>
						<p className={"is-size-6 has-text-primary"}>Today's: ${adminStats.gpt4_usage_cost_today?.toFixed(7)}</p>
						<p className={"is-size-6 has-text-primary"}>Last 7 Days: ${adminStats.gpt4_usage_cost_last_7_days?.toFixed(7)}</p>
						<p className={"is-size-6 has-text-primary"}>All time: ${adminStats.gpt4_usage_cost_all_time?.toFixed(7)}</p>
				</Card>
				<Card className={"mb-4"}>
						<div className={"is-flex"}>
						<FontAwesomeIcon icon={"money-bill-1"} className={"mr-4 has-text-success"} size={'lg'} />
							<h5 className={`is-size-5 secondary-font`}>Article Feedback Stats</h5>
						</div>
						<p className={"is-size-6 has-text-primary"}>Total Feedbacks: {adminStats.article_feedback_stats.total_feedbacks}</p>
						<p className={"is-size-6 has-text-primary"}>Positive Feedbacks: {adminStats.article_feedback_stats.positive_feedbacks}</p>
						<p className={"is-size-6 has-text-primary"}>Negative Feedbacks: {adminStats.article_feedback_stats.negative_feedbacks}</p>
				</Card>
			</div>
			<h4 className={`is-size-3 secondary-font mt-5`}>Mini AI Tools Stat</h4>
			{miniToolStatIsError ? <PageLoadingError/> :
			<div className={"admin-stats-container"}>
				{miniToolStatIsLoading ?<PageLoading/> : toolStats.tools.map((tool, index) => (
					<Card className="mt-3" key={index}>
						<div className={"is-flex"}>
							<h5 className={`is-size-5 secondary-font`}>{tool}</h5>
						</div>
						<p className={"is-size-6 has-text-primary"}>7 days Usage: {toolStats.tool_usage[`${tool}`]['last_seven_days_usage']}</p>
						<p className={"is-size-6 has-text-primary"}>Total Usage: {toolStats.tool_usage[`${tool}`]['total_usage']}</p>

					</Card>
				))}
			</div>}
		</>
	)
}
