import "./AllUsers.scss";
import PageLoading from "components/PageLoading/PageLoading";
import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getAllUserData, retryFn} from "../../utils/api";
import PageLoadingError from "components/PageLoadingError/PageLoadingError";
import Table from "components/Table/Table";
import {ColumnDef, createColumnHelper, FilterFn} from "@tanstack/react-table";
import {Link} from "react-router-dom";
import {DateTime} from "luxon";
import DatePicker from "react-datepicker";
import Input from "components/Input/Input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface AllUsersData {
	id: number
	username: string
	email: string
	website: string
	titles_generated: number
	articles_generated: number
	last_login: string
	date_joined: string
	keywords: number
	keyword_projects: number
	plan_name: number
}

export default function AllUsers() {
	// ---------------------- NON STATE CONSTANTS ----------------------
	const pageSizes = [100, 500, 1000];

	// ---------------------- STATES ----------------------
	const [searchBoxFilter, setSearchBoxFilter] = useState('');
	const [dateRange, setDateRange] = useState([null, null]);
	const [startDate, endDate] = dateRange;
	const [
		allUsersData,
		setAllUsersData
	] = useState<Array<AllUsersData>>([]);
	const [selectedPlan, setSelectedPlan] = useState('all');

	// ---------------------- QUERIES ----------------------
	const {
		isFetching,
		isError,
		error,
		data
	} = useQuery({
		queryKey: ['getAllUserData'],
		queryFn: getAllUserData,
		refetchOnWindowFocus: false,
		retry: retryFn
	});

	// ---------------------- EFFECTS ----------------------
	useEffect(() => {
		if (data) {
			setAllUsersData(data['data']);
		}
	}, [data]);

	// ---------------------- TABLE CUSTOM FILTERS ----------------------
	const joinedDateFilter: FilterFn<any> = (row, columnId, value: [Date, Date]) => {
		if (value[0] === null && value[1] === null) {
			return true
		} else {
			let columnDateString: string = row.getValue(columnId);
			if (columnDateString) {
				let columnDate = DateTime.fromISO(columnDateString);
				let filterStartDate = DateTime.fromISO(value[0].toISOString());
				let filterEndDate = DateTime.fromISO(value[1].toISOString());
				return (filterStartDate <= columnDate) && (filterEndDate >= columnDate)
			} else {
				return false;
			}
		}
	}

	// ---------------------- TABLE COLUMN DEFS ----------------------
	const columnHelper = createColumnHelper<AllUsersData>();
	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: AllUsersData) => row.email, {
			id: 'email_id',
			header: "Email ID",
			cell: props => {
				return (
					<Link to={props.row.original.id.toString()}>
						{props.row.original.email}
					</Link>
				)
			},
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: AllUsersData) => row.username, {
			id: 'username',
			header: "Name",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: AllUsersData) => row.plan_name, {
			id: 'plan_name',
			header: "Plan",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: AllUsersData) => row.website, {
			id: 'website',
			header: "Website",
			// NOTE: "---" will still be seen as undefined or null in filter functions.
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: AllUsersData) => row.titles_generated, {
			id: 'titles_generated',
			header: "Titles",
			cell: info => info.getValue(),
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: AllUsersData) => row.articles_generated, {
			id: 'articles_generated',
			header: "Articles",
			cell: info => info.getValue(),
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: AllUsersData) => row.keywords, {
			id: 'keywords',
			header: "KWs",
			cell: info => info.getValue(),
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: AllUsersData) => row.keyword_projects, {
			id: 'keyword_projects',
			header: "KW Proj",
			cell: info => info.getValue(),
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: AllUsersData) => row.last_login, {
			id: 'last_login',
			header: "Last Login (IST)",
			cell: info => DateTime.fromISO(info.getValue()).setZone("Asia/Kolkata").toLocaleString(DateTime.DATETIME_MED),
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: AllUsersData) => row.date_joined, {
			id: 'date_joined',
			header: "Date Joined (IST)",
			cell: info => DateTime.fromISO(info.getValue()).setZone("Asia/Kolkata").toLocaleString(DateTime.DATETIME_MED),
			enableGlobalFilter: false,
			filterFn: joinedDateFilter
		}),
		// columnHelper.display({
		// 	id: 'manage_user',
		// 	header: "Manage User",
		// 	cell: props => {
		// 		return (
		// 			<Link to={props.row.original.id.toString()} className={"button is-primary"}>
		// 				Manage
		// 			</Link>
		// 		)
		// 	},
		// 	enableGlobalFilter: false,
		// 	enableColumnFilter: false,
		// })
	]

	// ==============================================================
	// ---------------------- MAIN RENDER CODE ----------------------
	// ==============================================================
	if (isFetching) return <PageLoading/>;

	if (isError) return <PageLoadingError message={error as unknown as string}/>

	return (
		<div className={"all-users-container"}>
			<div className={"table-controls"}>
				{/* -------------------------- SEARCH BOX -------------------------- */}
				<Input type={"text"}
							 className={"table-searchbox"}
							 placeholder={"Search for Users..."}
							 value={searchBoxFilter ?? ''}
							 onChange={(e) => {
								 setSearchBoxFilter(String(e.target.value));
							 }}/>
				<div className={"table-custom-controls"}>
					<label className={"label is-flex is-align-items-center table-custom-control--item"}>
						<FontAwesomeIcon icon={['far', 'calendar-days']} size={'xl'}/>&nbsp;&nbsp;&nbsp;
						<DatePicker
							selectsRange={true}
							startDate={startDate}
							endDate={endDate}
							onChange={(update) => {
								setDateRange(update);
							}}
							peekNextMonth
							showMonthDropdown
							showYearDropdown
							dropdownMode="select"
							className={"input"}
							isClearable={true}
							placeholderText={"Click for Date Filter"}
						/>
					</label>
					<div className={"select table-custom-control--item"}>
						<select value={selectedPlan} onChange={e => setSelectedPlan(e.target.value)}>
							<option value="all">All Plans</option>
							<option value="trial">Trial Plan</option>
							<option value="basic">Basic Plan</option>
							<option value="pro">Pro Plan</option>
							<option value="pro_max">Pro Max Plan</option>
						</select>
					</div>
				</div>
			</div>
			<Table tableContentName={"Users"}
						 tableData={allUsersData}
						 columnDefs={columnDefs}
						 pageSizes={pageSizes}
						 initialPageSize={pageSizes[0]}
						 noDataText={"No Users Found"}
						 searchBoxValue={searchBoxFilter}
						 dateJoinedValue={dateRange}/>
		</div>
	)
}
