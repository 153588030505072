import "./IgnoredCompetitorDomains.scss";
import {useEffect, useRef, useState} from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
	getIgnoredCompetitorDomains,
	addCompetitorDomainsToIgnore,
	removeIgnoredCompetitorDomains,
	retryFn,
} from "utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {ColumnDef, createColumnHelper, RowData, RowModel} from "@tanstack/react-table";
import AbunTable, {IndeterminateCheckbox} from "components/AbunTable/AbunTable";
import Modal from "../../components/Modal/Modal";
import TextArea from "../../components/TextArea/TextArea";
import Alert from "../../components/Alert/Alert";

interface IgnoredCompetitorDomains {
	websiteDomain: string
}

let err_message = "";

// A simple page that shows a list of ignored competitors, allows adding new ones one at a time and deleting existing ones in bulk.
export default function IgnoredCompetitors() {
	// ---------------------- NON STATE CONSTANTS ----------------------
	const pageSizes = [15, 50, 100];

	// -------------------------- STATES --------------------------
	const [ignoredCompetitors, setIgnoredCompetitors] = useState<Array<IgnoredCompetitorDomains>>([]);
	const [selectedRows, setSelectedRows] = useState<RowModel<RowData>>();
	const [AddDomainsToIgnoreModal, setDomainModal] = useState(false);
	const [RemoveActionConfirmationModal, setRemoveActionConfirmationModal] = useState(false);
	const [DomainSearch, setDomainSearch] = useState("");

	// -------------------------- QUERIES --------------------------
	const {
		isFetching,
		isError,
		data,
		refetch,
	} = useQuery({
		queryKey: ['getIgnoredCompetitorDomains'],
		queryFn: getIgnoredCompetitorDomains,
		refetchOnWindowFocus: false,
		retry: retryFn
	});

	// -------------------------- MUTATIONS --------------------------
	const addIgnoredCompetitorMutation = useMutation({
		mutationKey: ['addCompetitorDomainsToIgnore'],
		mutationFn: addCompetitorDomainsToIgnore,
		gcTime: 0,
		onSuccess: (response) => {
			if (!err_message && response?.data?.bad_domains?.length < 1 && response?.data?.duplicate_domains?.length < 1) {
				successAlert.current?.show("Domain(s) have been added successfully!");
				err_message = "";
			} else {
				if (err_message && response?.data?.bad_domains?.length > 0) {
					err_message = `[${err_message},${response.data.bad_domains.join(",")}] are invalid domains.`;
				} else if (response?.data?.bad_domains?.length > 0) {
					err_message = `[${response.data.bad_domains.join(", ")}] are invalid domains.`;
				}
				if (response?.data?.duplicate_domains?.length > 0) {
					err_message += `\n [${response.data.duplicate_domains.join(", ")}] are already in the list.`;
				}
				errorAlert.current?.show(err_message);
				err_message = "";
			}
			setDomainSearch("");
			setIgnoredCompetitors(response.data.all_domains.reverse());
		},
		onError: (error) => {
			errorAlert.current?.show(`Failed to save domain: ${error}`);
			setDomainSearch("");
		},
	});

	const removeIgnoredCompetitorsMutation = useMutation({
		mutationKey: ['removeIgnoredCompetitorDomains'],
		mutationFn: removeIgnoredCompetitorDomains,
		gcTime: 0,
		onSuccess: (response) => {
			successAlert.current?.show("Domain(s) have been removed successfully!");
			setDomainSearch("");
			setIgnoredCompetitors(response.data.all_domains.reverse());
		},
		onError: (error) => {
			errorAlert.current?.show(`Failed to remove domains: ${error}`);
			setDomainSearch("");
		},
	})

	// ---------------------- EFFECTS ----------------------
	useEffect(() => {
		if (data) {
			setIgnoredCompetitors(data['data'].reverse());
		}
	}, [data]);

	// --------------------- REFS ---------------------
	const errorAlert = useRef<any>(null);
	const successAlert = useRef<any>(null);
	
	// ---------------------- FUNCTIONS ----------------------
	function selectedRowsSetter(rowModel: RowModel<RowData>) {
		setSelectedRows(rowModel);
	}

	function onAddDomainsToIgnore(UserInputs: string) {
		// split the input by comma or newline
		const allDomains = UserInputs?.split(",").join("\n").split("\n");
		let invalidDomains: Array<string> = [];
		let validDomains: Array<string> = [];
		allDomains?.forEach(input => {
			if (input) {
				// remove spaces from the input and convert to lowercase
				let domain: string = input.replace(/\s/g, "").toLowerCase();
				const isValidUrlOrDomain = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/i.test(domain);
				if (isValidUrlOrDomain) {
					// if not a domain, get the domain from the URL
					if (domain.includes("http")) {
						domain = new URL(input).hostname;
					}
					// remove any path from the domain name
					if (domain.includes("/")) {
						domain = domain.split("/")[0];
					}
					// check if the domain is already in the list
					if (validDomains.includes(domain)) {
						invalidDomains.push(domain);
					} else {
						validDomains.push(domain);
					}
				} else {
					invalidDomains.push(input);
				}
			}
		});
		if (invalidDomains.length > 0 && validDomains.length < 1) {
			errorAlert.current?.show(`Entered domains are invalid: ${invalidDomains.join(", ")}`);
			return;
		} else if (validDomains.length < 1) {
			errorAlert.current?.show("Please enter at least one valid domain. ");
			return;
		} else {
			setDomainModal(false);
			addIgnoredCompetitorMutation.mutate(validDomains);
		}
		if (invalidDomains.length > 0) {
			err_message += invalidDomains;
		} else {
			err_message = "";
		}
	}

	function onRemoveIgnoredCompetitorslist(webisteDomains: Array<string>) {
		if (webisteDomains.length < 1) {
			errorAlert.current?.show("Please select at least one domain to remove.");
			return;
		}
		// on remove multiple selected domains from the list
		removeIgnoredCompetitorsMutation.mutate(webisteDomains);
	}

	// ---------------------- TABLE COLUMN DEFS ----------------------
	const columnHelper = createColumnHelper<IgnoredCompetitorDomains>();
	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: IgnoredCompetitorDomains) => row.websiteDomain, {
			id: 'checkbox',
			header: ({table}) => (
				<IndeterminateCheckbox
					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: table.getToggleAllRowsSelectedHandler(),
					}}
				/>
			),
			cell: ({row}) => (
				<IndeterminateCheckbox
					{...{
						checked: row.getIsSelected(),
						disabled: !row.getCanSelect(),
						indeterminate: row.getIsSomeSelected(),
						onChange: row.getToggleSelectedHandler(),
					}}
					name={"ignoredCompetitorSelection"}
					value={row.original.websiteDomain}
				/>
			),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: IgnoredCompetitorDomains) => row.websiteDomain, {
			id: 'websiteDomain',
			header: "Website Domain",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.display({
			id: 'removeIgnoredCompetitor',
			header: () => (<div style={{textAlign: "center"}}>Remove Domain</div>),
			cell: props => {
				return (
					<button className={"button is-danger is-small is-outlined"}
						onClick={() => {
							props.row.toggleSelected();
							setRemoveActionConfirmationModal(true);
						}
						}>
						Remove&nbsp;&nbsp;
					</button>
				);
			},
			enableGlobalFilter: false,
			meta: {
				align: 'center'
			}
		}),
	]
	// ============================================================
	// --------------------- MAIN RENDER CODE ---------------------
	// ============================================================
	if (isFetching) {
		return (
			<p style={{ textAlign: "center", fontSize: "1.3rem" }} className="mt-5">
				Loading Data...<FontAwesomeIcon icon={"spinner"} className={"ml-5"} />
			</p>
		)
	} else if (isError) {
		return (
			<section className="section">
				<div className="container">
					<div className="box">
						<h1 className="title has-text-centered">Ignored Competitors</h1>
						<p className="has-text-centered is-size-5">
							Failed to load data. Please try again later.
						</p>
					</div>
				</div>
			</section>
		);
	} else {
		return (
			<section className="section">
				<div className="container">
					<div className="box">
						{/* ******************* Add Ignore Domain Modal ******************* */}
						<Modal active={AddDomainsToIgnoreModal}
							headerText={""}
							closeable={true}
							hideModal={() => {
								setDomainModal(false);
								setDomainSearch("");
							}
							}>
							<h1 className="title has-text-centered">Add Domains to Ignore</h1>
							<TextArea
								className={"textarea"}
								id={"addCompetitorDomainsToIgnore"}
								name={"addCompetitorDomainsToIgnore"}
								placeholder={"Add domains to ignore (separated by commas or newlines)"}
								value={DomainSearch}
								onChange={(event) => setDomainSearch(event.target.value)}
							/>
							<button className={"button is-primary is-fullwidth mt-4"}
								onClick={() => onAddDomainsToIgnore(DomainSearch)}>
								Add Domains to Ignore
							</button>
						</Modal>

						{/* ******************* Remove confirmation Modal ******************* */}
						<Modal active={RemoveActionConfirmationModal}
							headerText={""}
							closeable={true}
							hideModal={() => setRemoveActionConfirmationModal(false)}>
							<h1 className="title has-text-centered">Are you sure you want to remove the selected domain(s)?</h1>
							<div className="buttons is-centered">
								<button className={"button is-danger is-outlined"}
									onClick={() => {
										setRemoveActionConfirmationModal(false);
										if (selectedRows && selectedRows.rows.length > 0) {
											const selectedDomains = selectedRows.rows.map(row => {
												return (row.original as IgnoredCompetitorDomains).websiteDomain
											});
											onRemoveIgnoredCompetitorslist(selectedDomains);
											selectedRows.rows.forEach(row => {
												row.toggleSelected();
											});
										}
									}}>
									Remove Selected
								</button>
								<button className={"button is-primary is-outlined"}
									onClick={() => setRemoveActionConfirmationModal(false)}>
									Cancel
								</button>
							</div>
						</Modal>

						<AbunTable tableContentName={"Ignored Competitors"}
							tableData={
								ignoredCompetitors.map((domain) => {
									return {
										websiteDomain: domain
									}
								})
							}
							columnDefs={columnDefs}
							pageSizes={pageSizes}
							initialPageSize={pageSizes[0]}
							noDataText={
								ignoredCompetitors.length === 0 ?
								"No ignored competitors domains available."
								: "No domains found."
							}
							searchboxPlaceholderText={"Search domains..."}
							rowCheckbox={true}
							selectedRowsSetter={selectedRowsSetter}
							buttons={[
								{
									text: "Add Domains to Ignore",
									type: "primary",
									clickHandler: () => setDomainModal(true)
								},
								{
									text: "Remove All Selected",
									type: "danger",
									clickHandler: () => setRemoveActionConfirmationModal(true),
									invisible: !selectedRows || selectedRows.rows.length < 1
								},
								{
									text: "Refresh",
									type: "primary",
									clickHandler: () => refetch()
								}
							]} />
					</div>
				</div>
				<Alert type={"danger"} ref={errorAlert}/>
				<Alert type={"success"} ref={successAlert}/>
			</section>
		);
	}
}