import "./BackLinks.scss";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
    getBackLinks,
    addBackLinks,
    removeBackLinks,
    toggleBackLinkVisibility,
    retryFn,
} from "utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef, createColumnHelper, RowData, RowModel } from "@tanstack/react-table";
import AbunTable, { IndeterminateCheckbox } from "components/AbunTable/AbunTable";
import Modal from "components/Modal/Modal";
import Alert from "components/Alert/Alert";

interface BackLink {
    name: string,
    url: string,
    da_score: number,
    follow_unfollow_link: string,
    submissions: string,
    article_link: string,
    show_on_free_plan: boolean
}

let err_message = "";

// A simple page that shows a list of block website keywords, allows adding new ones one at a time and deleting existing ones in bulk.
export default function BackLinks() {
    // ---------------------- NON STATE CONSTANTS ----------------------
    const pageSizes = [15, 50, 100];

    // -------------------------- STATES --------------------------
    const [backlink, setBacklink] = useState<Array<BackLink>>([]);
    const [selectedRows, setSelectedRows] = useState<RowModel<RowData>>();
    const [addBacklinkToModal, setAddBacklinkToModal] = useState(false);
    const [RemoveActionConfirmationModal, setRemoveActionConfirmationModal] = useState(false);
    const [file, setFile] = useState(null);

    // -------------------------- QUERIES --------------------------
    const {
        isFetching,
        isError,
        data,
        refetch,
    } = useQuery({
        queryKey: ['getBackLinks'],
        queryFn: getBackLinks,
        refetchOnWindowFocus: false,
        retry: retryFn
    });

    // -------------------------- MUTATIONS --------------------------
    const addBackLinksMutation = useMutation({
        mutationKey: ['addBackLinks'],
        mutationFn: addBackLinks,
        gcTime: 0,
        onSuccess: (response) => {
            if (!err_message) {
                successAlert.current?.show("Backlinks have been added successfully!");
                err_message = "";
            } else {
                errorAlert.current?.show(err_message);
                err_message = "";
            }
            setBacklink(response.data.backlinks);
            setAddBacklinkToModal(false)
            setFile(null)
        },
        onError: (error) => {
            errorAlert.current?.show(`Failed to save block website backlinks: ${error}`);
        },
    });

    const removeBackLinksMutation = useMutation({
        mutationKey: ['removeBackLinks'],
        mutationFn: removeBackLinks,
        gcTime: 0,
        onSuccess: (response) => {
            successAlert.current?.show("Backlinks have been removed successfully!");
            setBacklink(response.data.backlinks);
        },
        onError: (error) => {
            errorAlert.current?.show(`Failed to remove backlinks: ${error}`);
        },
    })

    const toggleBacklinkMutation = useMutation({
        mutationKey: ['toggleBackLinkVisibility'],
        mutationFn: toggleBackLinkVisibility,
        gcTime: 0,
        onSuccess: (response) => {
            successAlert.current?.show("Backlinks visibility updated successfully!");
            console.log('backlinks--',response.data.backlinks)
            setBacklink(response.data.backlinks);
        },
        onError: (error) => {
            errorAlert.current?.show(`Failed to update backlink visibility: ${error}`);
        },
    })

    // ---------------------- EFFECTS ----------------------
    useEffect(() => {
        if (data) {
            setBacklink(data['data']['backlinks']);
        }
    }, [data]);

    // --------------------- REFS ---------------------
    const errorAlert = useRef<any>(null);
    const successAlert = useRef<any>(null);

    // ---------------------- FUNCTIONS ----------------------
    function selectedRowsSetter(rowModel: RowModel<RowData>) {
        setSelectedRows(rowModel);
    }

    function onRemoveBackLinks(names: Array<string>) {
        if (names.length < 1) {
            errorAlert.current?.show("Please select at least one backlink to remove.");
            return;
        }
        // on remove multiple selected backlinks from the list
        removeBackLinksMutation.mutate({
            names: names,
        });
    }

    function onToggleBackLinks(name: string) {
        if (!name) {
            errorAlert.current?.show("Please select at least one backlink to remove.");
            return;
        }
        // on toggle backlink visibility from the list
        toggleBacklinkMutation.mutate({
            name: name,
        });
    }


    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            errorAlert.current?.show("Please Upload a CSV file.");
            return;
        }
        addBackLinksMutation.mutate({
            csv_file: file,
        });
    }
    // ---------------------- TABLE COLUMN DEFS ----------------------
    const columnHelper = createColumnHelper<BackLink>();
    const columnDefs: ColumnDef<any, any>[] = [
        columnHelper.accessor((row: BackLink) => row.name, {
            id: 'checkbox',
            header: ({ table }) => (
                <IndeterminateCheckbox
                    {...{
                        checked: table.getIsAllRowsSelected(),
                        indeterminate: table.getIsSomeRowsSelected(),
                        onChange: table.getToggleAllRowsSelectedHandler(),
                    }}
                />
            ),
            cell: ({ row }) => (
                <IndeterminateCheckbox
                    {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }}
                    name={"blockWebsiteKeywordSelection"}
                    value={row.original.name}
                />
            ),
            enableGlobalFilter: true,
        }),
        columnHelper.accessor((row: BackLink) => row.name, {
            id: 'name',
            header: "Name",
            cell: info => info.getValue(),
            enableGlobalFilter: true,
        }),
        columnHelper.accessor((row: BackLink) => row.url, {
            id: 'url',
            header: "Url",
            cell: info => info.getValue(),
            enableGlobalFilter: true,
        }),
        columnHelper.accessor((row: BackLink) => row.da_score, {
            id: 'da_score',
            header: "DA Score",
            cell: info => info.getValue(),
            enableGlobalFilter: true,
        }),
        columnHelper.accessor((row: BackLink) => row.follow_unfollow_link, {
            id: 'follow_unfollow_link',
            header: "Follow/Unfollow Link",
            cell: info => info.getValue(),
            enableGlobalFilter: true,
        }),
        columnHelper.accessor((row: BackLink) => row.submissions, {
            id: 'submissions',
            header: "Submissions",
            cell: info => info.getValue(),
            enableGlobalFilter: true,
        }),
        columnHelper.accessor((row: BackLink) => row.article_link, {
            id: 'article_link',
            header: "Article Link",
            cell: info => info.getValue(),
            enableGlobalFilter: true,
        }),
        columnHelper.accessor((row: BackLink) => row.show_on_free_plan, {
            id: 'show_on_free_plan',
            header: "Show On Free Plan",
            cell: props => {
                if (props.row.original.show_on_free_plan){
                    return (
                        <input type="checkbox" onChange={ e=> {
                            onToggleBackLinks(props.row.original.name)}} checked={true}/>
                    )
                } else {
                    return (
                        <input type="checkbox" onChange={ e=> {
                            onToggleBackLinks(props.row.original.name)}} checked={false}/>
                )
                }
            },
            enableGlobalFilter: true,
        }),
    ]
    
    // ============================================================
    // --------------------- MAIN RENDER CODE ---------------------
    // ============================================================
    if (isFetching) {
        return (
            <p style={{ textAlign: "center", fontSize: "1.3rem" }} className="mt-5">
                Loading Data...<FontAwesomeIcon icon={"spinner"} className={"ml-5"} />
            </p>
        )
    } else if (isError) {
        return (
            <section className="section">
                <div className="container">
                    <div className="box">
                        <h1 className="title has-text-centered">Blocked Website Keywords</h1>
                        <p className="has-text-centered is-size-5">
                            Failed to load data. Please try again later.
                        </p>
                    </div>
                </div>
            </section>
        );
    } else {
        return (
            <section className="section">
                <div className="container">
                    <div className="box">
                        {/* ******************* Add BackLinks Modal ******************* */}
                        <Modal active={addBacklinkToModal}
                            headerText={"Add BackLinks"}
                            closeable={true}
                            hideModal={() => {
                                setAddBacklinkToModal(false);
                            }
                            }>
                            <div className="tab-content">
                                <form onSubmit={handleSubmit}>
                                    <input type="file" className="input form-control" onChange={handleFileChange} accept=".csv"/>
                                    <button type="submit" className={"button is-primary is-fullwidth mt-4"}>Upload CSV</button>
                                </form>
                            </div>
                        </Modal>

                        {/* ******************* Remove confirmation Modal ******************* */}
                        <Modal active={RemoveActionConfirmationModal}
                            headerText={""}
                            closeable={true}
                            hideModal={() => setRemoveActionConfirmationModal(false)}>
                            <h1 className="title has-text-centered">Are you sure you want to remove the selected backlink(s)?</h1>
                            <div className="buttons is-centered">
                                <button className={"button is-danger is-outlined"}
                                    onClick={() => {
                                        setRemoveActionConfirmationModal(false);
                                        if (selectedRows && selectedRows.rows.length > 0) {
                                            const selectedNames = selectedRows.rows.map(row => {
                                                return (row.original as BackLink).name
                                            });
                                            onRemoveBackLinks(selectedNames);
                                            selectedRows.rows.forEach(row => {
                                                row.toggleSelected();
                                            });
                                        }
                                    }}>
                                    Remove Selected
                                </button>
                                <button className={"button is-primary is-outlined"}
                                    onClick={() => setRemoveActionConfirmationModal(false)}>
                                    Cancel
                                </button>
                            </div>
                        </Modal>

                        <AbunTable tableContentName={"BackLinks"}
                            tableData={backlink}
                            columnDefs={columnDefs}
                            pageSizes={pageSizes}
                            initialPageSize={pageSizes[0]}
                            noDataText={
                                backlink.length === 0 ?
                                    "No backlinks available."
                                    : "No backlinks found."
                            }
                            searchboxPlaceholderText={"Search backlinks..."}
                            rowCheckbox={true}
                            selectedRowsSetter={selectedRowsSetter}
                            buttons={[
                                {
                                    text: "Add BackLinks",
                                    type: "primary",
                                    clickHandler: () => setAddBacklinkToModal(true)
                                },
                                {
                                    text: "Remove All Selected BackLinks",
                                    type: "danger",
                                    clickHandler: () => setRemoveActionConfirmationModal(true),
                                    invisible: !selectedRows || selectedRows.rows.length < 1
                                },
                                {
                                    text: "Refresh",
                                    type: "primary",
                                    clickHandler: () => refetch()
                                }
                            ]} />
                    </div>
                </div>
                <Alert type={"danger"} ref={errorAlert} />
                <Alert type={"success"} ref={successAlert} />
            </section>
        );
    }
}
