import "./CSVUpload.scss"
import { ChangeEvent, useRef, useState } from "react";
import Papa, { ParseResult } from "papaparse";
import uploadIcon from "../../assets/images/icons/cloud-upload.svg";
import { UseMutationResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";


interface CSVUploadProps {
    title: string
    name: string
    csvUploadDone: () => void
    mutation: UseMutationResult<AxiosResponse<any, any>, Error, any, unknown>;
    mutation_params?: object;
}

interface ColumnSelectionProps {
    title: string
    name: string
    columns: Array<string>
    data: Array<any>
    csvUploadDone: () => void
    mutation: UseMutationResult<AxiosResponse<any, any>, Error, any, unknown>;
    mutation_params?: object;
}

// ==========================================================================
// -------------------------- CSV UPLOAD COMPONENT --------------------------
// ==========================================================================

export default function CSVUpload(props: CSVUploadProps) {
    // ------------------- STATES -------------------
    const [fileName, setFileName] = useState("");
    const [columns, setColumns] = useState<Array<string>>([]);
    const [data, setData] = useState<Array<any>>([]);

    // ------------------- REFS -------------------
    const fileInput = useRef<HTMLInputElement>(null);

    function openFileExplorer() {
        fileInput.current?.click();
    }

    function onFileSelected(e: ChangeEvent<HTMLInputElement>) {
        // set filename text
        let filename: string = (e.target.value.split(/([\\/])/g).pop() as string);
        setFileName(filename);

        // read file and update column names
        if (e.target.files) {
            let file = e.target.files[0]
            if (file) {
                Papa.parse((file as any), {
                    complete(results: ParseResult<any>) {
                        let columns = results.data[0];
                        let csvData = results.data.splice(1, results.data.length);
                        setColumns(columns);
                        setData(csvData);
                    }
                })
            }
        }
    }


    return (
        <div className="csv-upload">
            <div className="csv-div"
                onClick={openFileExplorer}
                onDragOver={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onDrop={(e) => {
                    e.stopPropagation();
                    e.preventDefault();

                    if (e.dataTransfer.items) {
                        const file = e.dataTransfer.items[0].getAsFile();
                        if (file) {
                            setFileName(file.name);
                            // read using papaparse
                            Papa.parse((file as any), {
                                complete(results: ParseResult<any>) {
                                    let columns = results.data[0];
                                    let csvData = results.data.splice(1, results.data.length);
                                    setColumns(columns);
                                    setData(csvData);
                                }
                            });
                        }
                    }
                }}>
                <img src={uploadIcon} alt="upload-icon" />
                <p>Click to <b>choose a file</b> or drag it here</p>
                <p className={"csv-filename"}>
                    {fileName ? fileName : "No file selected"}
                </p>
                <input type="file" accept={"text/csv"} ref={fileInput} onChange={onFileSelected} />
            </div>
            {columns.length > 0 ?
                <ColumnSelection columns={columns} data={data} csvUploadDone={props.csvUploadDone}
                mutation={props.mutation} title={props.title} name={props.name} mutation_params={props.mutation_params} /> : <></>}
        </div>
    )
}

// ================================================================================
// -------------------------- COLUMN SELECTION COMPONENT --------------------------
// ================================================================================

function ColumnSelection(props: ColumnSelectionProps) {
    // ------------------- STATES -------------------
    const [columnIndex, setColumnIndex] = useState(0);
    let [text, setText] = useState('');
    let [textStyle, setTextStyle] = useState('')
    let [uploading, setUploading] = useState(false)
    let csvData: Array<string> = []
    props.data.map((row) => {
        if (row[columnIndex]) {
            csvData.push(row[columnIndex])
        }
    })

    return (
        <div className={"column-selection-div"}>
            <label className="label">{props.title}</label>
            <div className="select">
                <select value={columnIndex}
                    onChange={e => setColumnIndex(parseInt(e.target.value))}>
                    {props.columns.map((columnName, index) => (
                        <option value={index} key={`${columnName}-${index}`}>{columnName}</option>
                    ))
                    }
                </select>
            </div>
            <span className="my-3" style={{ color: textStyle }}>{text}</span>
            <button className={"button is-primary is-fullwidth mt-4"}
                onClick={() => {
                    if(csvData.length === 0){
                        setText(`No ${props.name} found`);
                        setTextStyle('red');
                    }
                    else if (csvData.length <= 100000) {
                        setUploading(true)
                        props.mutation.mutate({...props.mutation_params}, {
                            onSuccess: (response) => {
                                props.csvUploadDone();
                                setUploading(false);
                            },
                            onError: (error) => {
                                setText('Oops, Something went wrong! Please Retry.')
                                setTextStyle('red')
                                setUploading(false)
                            }

                        });
                    }
                    else {
                        setText(`Max 100k ${props.name} should be submitted at once.`)
                        setTextStyle('red')
                    }

                }} disabled={uploading}>
                {!uploading ? `Add ${props.name} to Block` : `Adding ${props.name} ...`}
            </button>
        </div>
    )
}
