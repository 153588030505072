import Card from "components/Card/Card";
import { useEffect, useState } from "react";

export default function Settings() {
	// State variables to track the selected dark mode options
	const [alwaysDarkMode, setDarkMode] = useState(
		Boolean(localStorage.getItem("darkmode"))
	);
	const [followSysTheme, setAutoDarkMode] = useState(
		Boolean(localStorage.getItem("followSysTheme"))
	);

	useEffect(() => {
		// Check if the user's system theme is set to dark mode
		const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)');

		// Function to handle theme change based on system preference
		const handleThemeChange = (event: MediaQueryListEvent) => {
			document.body.classList.toggle("dark", event.matches);
		};

		// Function to enable follow system theme option
		const enableFollowSysTheme = () => {
			localStorage.setItem("followSysTheme", "on");
			localStorage.removeItem("darkmode");
			setDarkMode(false);
			prefersDarkMode.addEventListener('change', handleThemeChange);
			document.body.classList.toggle("dark", prefersDarkMode.matches);
		};

		// Function to disable follow system theme option
		const disableFollowSysTheme = () => {
			localStorage.removeItem("followSysTheme");
			prefersDarkMode.removeEventListener('change', handleThemeChange);
			document.body.classList.remove("dark");
		};

		// Function to enable always dark mode option
		const enableAlwaysDarkMode = () => {
			localStorage.setItem("darkmode", "on");
			document.body.classList.add("dark");
			localStorage.removeItem("followSysTheme");
			setAutoDarkMode(false);
		};

		// Function to disable always dark mode option
		const disableAlwaysDarkMode = () => {
			localStorage.removeItem("darkmode");
			if (!followSysTheme) {
				document.body.classList.remove("dark");
			}
		};

		// Check the selected dark mode options and apply corresponding changes
		if (followSysTheme) {
			enableFollowSysTheme();
		} else {
			disableFollowSysTheme();
		}

		if (alwaysDarkMode) {
			enableAlwaysDarkMode();
		} else {
			disableAlwaysDarkMode();
		}
	}, [alwaysDarkMode, followSysTheme]);

	return (
		<Card>
			<h5 className="is-size-5 secondary-font">Dark Mode Settings</h5>
			<hr className="dropdown-divider" />
			<div className="field mt-4">
				<input
					id="switchColorDefault"
					type="checkbox"
					className="switch"
					checked={alwaysDarkMode}
					onChange={() => setDarkMode(!alwaysDarkMode)}
				/>
				<label htmlFor="switchColorDefault">
					&nbsp;Always On (Experimental)
				</label>
			</div>
			<div className="field mt-4">
				<input
					id="followSysThemeSwitchColorDefault"
					type="checkbox"
					className="switch"
					checked={followSysTheme}
					onChange={() => setAutoDarkMode(!followSysTheme)}
				/>
				<label htmlFor="followSysThemeSwitchColorDefault">
					&nbsp;Follow System Theme (Experimental)
				</label>
			</div>
		</Card>
	);
}
