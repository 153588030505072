import "./Sidebar.scss";

import abunLogo from "assets/images/branding/abun_blue_text_logo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {NavLink} from "react-router-dom";
import {pageURL} from "../../pages/routes";


export default function Sidebar() {
	return (
		<aside className="menu abun-sidebar">
			<div className={"sidebar-logo"}>
				<img src={abunLogo} alt={"abun logo"}/>
				<p>ADMIN</p>
			</div>
			{/* -------------------------------------------------------------------------------- */}
			<ul className="menu-list mt-6">
				<li>
					<NavLink to={pageURL['dashboard']}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<FontAwesomeIcon icon={"home"}/>&nbsp;&nbsp;Dashboard
					</NavLink>
				</li>
				<li>
					<NavLink to={pageURL['allUsers']}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<FontAwesomeIcon icon={"users"}/>&nbsp;&nbsp;All Users
					</NavLink>
				</li>
				<li>
					<NavLink to={pageURL['admins']}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<FontAwesomeIcon icon={"user-shield"}/>&nbsp;&nbsp;Admins
					</NavLink>
				</li>
				<li>
					<NavLink to={"/ignored-competitors"}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<FontAwesomeIcon icon={"link-slash"} />&nbsp;&nbsp;Ignored Competitors
					</NavLink>
				</li>
				<li>
					<NavLink to={"/blocked-domains"}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<FontAwesomeIcon icon={"link-slash"} />&nbsp;&nbsp;Block Domains At Signup
					</NavLink>
				</li>
				<li>
					<NavLink to={"/blocked-websites"}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<FontAwesomeIcon icon={"link-slash"} />&nbsp;&nbsp;Block Websites
					</NavLink>
				</li>
				<li>
					<NavLink to={"/blocked-website-keywords"}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<FontAwesomeIcon icon={"link-slash"} />&nbsp;&nbsp;Block Keywords
					</NavLink>
				</li>
				<li>
					<NavLink to={"/backlinks"}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<FontAwesomeIcon icon={"link-slash"} />&nbsp;&nbsp;Add BackLinks
					</NavLink>
				</li>
				<li>
					<NavLink to={"/auto-coupons"}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<FontAwesomeIcon icon={"link-slash"} />&nbsp;&nbsp;Add Coupons
					</NavLink>
				</li>
				<li>
					<NavLink to={pageURL['settings']}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<FontAwesomeIcon icon={"cog"}/>&nbsp;&nbsp;Settings
					</NavLink>
				</li>
				<li>
					<NavLink to={"/export/users"}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<FontAwesomeIcon icon={"download"}/>&nbsp;&nbsp;Export Users
					</NavLink>
				</li>
				<li>
					<NavLink to={"/logs"}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<FontAwesomeIcon icon={"note-sticky"}/>&nbsp;&nbsp;Logs
					</NavLink>
				</li>
				<li>
					<NavLink to={"/others"}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<FontAwesomeIcon icon={"tools"}/>&nbsp;&nbsp;Others
					</NavLink>
				</li>
			</ul>
			<hr className={"dropdown-divider"}/>
			{/* -------------------------------------------------------------------------------- */}
			<ul className="menu-list">
				<li>
					<NavLink to={pageURL['logout']}
									 className={({isActive}) => isActive ? "is-active" : ""}>
						<FontAwesomeIcon icon={"sign-out"}/>&nbsp;&nbsp;Log Out
					</NavLink>
				</li>
			</ul>
		</aside>
	)
}
