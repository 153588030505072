import "./Table.scss";
import "react-datepicker/dist/react-datepicker.css";

import {
	ColumnDef, ColumnFiltersState,
	FilterFn,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	useReactTable
} from "@tanstack/react-table";
import {useEffect, useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface TableProps {
	tableContentName: string
	tableData: Array<any>
	columnDefs: ColumnDef<any, any>[]
	pageSizes: Array<number>
	initialPageSize: number
	noDataText: string
	searchBoxValue?: string
	dateJoinedValue?: null[] | Date[]
}

const stringSearchFilter: FilterFn<any> = (row, columnId, value) => {
	let rowValue: string | number | undefined = row.getValue(columnId);
	if (rowValue !== undefined) {
		let cellValue: string = (rowValue as string | number).toString().toLowerCase();
		return cellValue.includes(value.toLowerCase());
	} else {
		return false;
	}
}

export default function Table(props: TableProps) {
	// ----------------------- STATES -----------------------
	const [globalFilter, setGlobalFilter] = useState('');
	const [
		columnFilters,
		setColumnFilters
	] = useState<ColumnFiltersState>([]);
	const [data, setData] = useState(() => [...props.tableData]);
	const columns = useMemo<ColumnDef<any, any>[]>(() => props.columnDefs, [
		props.columnDefs
	]);

	// ----------------------- REACT TABLE -----------------------
	const table = useReactTable({
		data: data,
		columns: columns,
		getCoreRowModel: getCoreRowModel(),
		state: {
			globalFilter,
			columnFilters
		},
		globalFilterFn: stringSearchFilter,
		onGlobalFilterChange: setGlobalFilter,
		onColumnFiltersChange: setColumnFilters,
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		initialState: {
			pagination: {
				pageSize: props.initialPageSize
			}
		},
	})

	// ----------------------- EFFECTS -----------------------
	useEffect(() => {
		setData([...props.tableData]);
	}, [props.tableData]);

	// For Search Box filter (Global)
	useEffect(() => {
		if (props.searchBoxValue !== undefined) {
			setGlobalFilter(props.searchBoxValue);
		}
	}, [props.searchBoxValue]);

	// For Date Joined filter (All Users page)
	useEffect(() => {
		if (props.dateJoinedValue !== undefined) {
			if (props.dateJoinedValue[0] === null && props.dateJoinedValue[1] === null) {
				// clear filter from date joined column
				table.getColumn('date_joined')?.setFilterValue(props.dateJoinedValue);
			} else if (props.dateJoinedValue[0] && props.dateJoinedValue[1]) {
				// add filter to date joined column
				table.getColumn('date_joined')?.setFilterValue(props.dateJoinedValue);
			}
		}
	}, [props.dateJoinedValue, table]);

	// ----------------------- FUNCTIONS -----------------------
	/**
	 * Returns JSX element with no data text if one of the no data conditions is met. Otherwise returns empty JSX element.
	 * Conditions:
	 * 1. Table data receied through props was empty (ex. no articles in db).
	 * 2. Number of rows in Tanstack Table object is 0 (ex. no filter matches).
	 */
	function getNoDataTextElement() {
		if (props.tableData.length <= 0) {
			return (
				<div className={"table-no-data"}>
					{props.noDataText}
				</div>
			)

		} else if (table.getRowModel().rows.length <= 0) {
			return (
				<div className={"table-no-data"}>
					No data matching this search query :(<br/>
					Clear the searchbox or try using a different search text.
				</div>
			)

		} else {
			return <></>
		}
	}

	// function handlePageNumberChange(newPageNum: string) {
	// 	let num: number;
	//
	// 	try {
	// 		// if user provided page number exceeds total pages, cap it to last page number;
	// 		num = parseInt(newPageNum);
	// 		if (num > table.getPageCount()) {
	// 			num = table.getPageCount();
	// 		} else if (num < 1) {
	// 			num = 1;
	// 		}
	// 	} catch (e) {
	// 		num = 1;
	// 	}
	//
	// 	// -1 because page index starts at 0
	// 	table.setPageIndex(num - 1);
	// }

	function goToPrevPage() {
		table.setPageIndex(table.getState().pagination.pageIndex - 1);
	}

	function goToNextPage() {
		table.setPageIndex(table.getState().pagination.pageIndex + 1)
	}

	function changePageSize(newSize: string) {
		let newSizeNumber
		try {
			newSizeNumber = parseInt(newSize);
		} catch (e) {
			newSizeNumber = 1
		}
		if (newSizeNumber <= 0) newSizeNumber = 1;
		table.setPageSize(newSizeNumber);
	}

	// ================================================================
	// ----------------------- MAIN RENDER CODE -----------------------
	// ================================================================
	return (
		<div className={"table-responsive"}>
			<div className={"table-container"}>
				<table className={"table is-fullwidth"}>
					<thead>
					{table.getHeaderGroups().map(headerGroup => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map(header => (
								<th key={header.id}>
									{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
								</th>
							))}
						</tr>
					))}
					</thead>
					<tbody>
					{table.getRowModel().rows.map(row => (
						<tr key={row.id}>
							{row.getVisibleCells().map(cell => (
								<td key={cell.id} align={(cell.column.columnDef.meta as any)?.align}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</td>
							))}
						</tr>
					))}
					</tbody>
				</table>
				{getNoDataTextElement()}
				<div className={"table-pagination-controls-container pl-5 pr-5"}>
					<div className={"table-pagination-size-select"}>
						Total <b>{props.tableData.length}</b> {props.tableContentName}&nbsp;&nbsp;|&nbsp;&nbsp;Show&nbsp;
						<div className="select is-small">
							<select defaultValue={props.initialPageSize} onChange={e => changePageSize(e.target.value)}>
								{props.pageSizes.map(size => (
									<option key={size} value={size}>{size}</option>
								))}
							</select>
						</div>
					</div>
					&nbsp;
					per page
					<div className={"table-pagination-page-control"}>
						<button className={"chevron-icons"} disabled={!table.getCanPreviousPage()} onClick={goToPrevPage}>
							<FontAwesomeIcon icon={"chevron-left"}/>
						</button>
						<div className={"page-input-container"}>
							<b>{(table.getState().pagination.pageIndex + 1).toString()}</b>
							{/*<Input type={"text"}*/}
							{/*			 className={"page-input"}*/}
							{/*			 value={(table.getState().pagination.pageIndex + 1).toString()}*/}
							{/*			 onChange={e => handlePageNumberChange(e.target.value)}/>*/}
							{/*<DelayedInput initialValue={(table.getState().pagination.pageIndex + 1).toString()}*/}
							{/*							delayInMilliseconds={500}*/}
							{/*							resetInput={undefined}*/}
							{/*							onChange={handlePageNumberChange}*/}
							{/*							additionalClasses={["page-input"]}/>*/}
							&nbsp;/&nbsp;{table.getPageCount()}
						</div>
						<button className={"chevron-icons"} disabled={!table.getCanNextPage()} onClick={goToNextPage}>
							<FontAwesomeIcon icon={"chevron-right"}/>
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
