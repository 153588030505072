import {ChangeEvent} from "react";

interface CheckBoxProps {
	checked: boolean
	onChange: (event: ChangeEvent<HTMLInputElement>) => void
	text: string
}

export default function CheckBox(props: CheckBoxProps) {
	return (
		<label className={"checkbox"}>
			<input type="checkbox" checked={props.checked} onChange={props.onChange}/>
			&nbsp;&nbsp;{props.text}
		</label>
	)
}
