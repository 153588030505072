import "./TaskLog.scss";
import Card from "../../components/Card/Card";
import {Link, useParams} from "react-router-dom";
import {pageURL} from "../routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useQuery, useMutation} from "@tanstack/react-query";
import {getTaskDetails, retryFn, updateArticle } from "../../utils/api";
import {useEffect, useState} from "react";
import PageLoading from "../../components/PageLoading/PageLoading";
import PageLoadingError from "../../components/PageLoadingError/PageLoadingError";
import {DateTime} from "luxon";
import Button from "components/Button/Button";
import AbunModal from "components/AbunModal/AbunModal";
import { Player } from '@lottiefiles/react-lottie-player';
import ViewArticle from "pages/ViewArticle/ViewArticle";

interface TaskData {
	user_email: string
	username: string
	created_on: string
	status: "running" | "completed" | "failed"
	retry_attempts: number
	metadata: string
	keyword: string
	logs: LogData[]
}

interface LogData {
	id: number
	type: "info" | "warning" | "error"
	message: string
	created_on: string
}

interface ArticleStatusData {
	is_processing: boolean
	is_failed: boolean
	is_generated: boolean
	is_posted: boolean
	is_archived: boolean
	is_user_added: boolean
	cost: number
}

export default function TaskLog() {
	// ------------------- REACT ROUTER PARAMS -------------------
	let {userId, jobId} = useParams();

	// ------------------- STATES -------------------
	const [
		taskData,
		setTaskData
	] = useState<TaskData | null>(null);

	const [
		articleData,
		setArticleData
	] = useState<ArticleStatusData | null>(null);

	const [
		editingLocked,
		setEditingLocked
	] = useState(true);

	const [
		actionModalActive,
		setActionModalActive
	] = useState(false);

	const [
		modalText,
		setModalText
	] = useState(<></>);

	const [
		showLoader,
		setShowLoader
	] = useState(false);

	const [
		isArticleDeleted,
		setIsArticleDeleted
	] = useState(false);

	// ------------------- QUERIES -------------------
	const {
		data,
		isFetching,
		isError,
		error,
		refetch
	} = useQuery({
		queryKey: ['getTaskDetails', userId, jobId],
		queryFn: () => getTaskDetails({userId: userId!, jobId: jobId!}),
		refetchOnWindowFocus: false,
		retry: retryFn,
	});

	// ------------------- MUTATIONS -------------------
	const updateArticleMut = useMutation({
		mutationKey: ['updateArticle'],
		mutationFn: updateArticle,
		gcTime: 0,
		onSuccess: () => {
			console.log("Article Data Updated");
		},
		onError: (error) => {
			console.log(`Failed to update article data: ${error}`);
		}
	});

	// ------------------- EFFECTS -------------------
	useEffect(() => {
		if (data) {
			if (data['data']["article-data"]) {
				setArticleData(data['data']["article-data"]);
			}
			setTaskData(data['data']);
		}
	}, [data]);

	useEffect(() => {
		if (articleData) {
			const articleStatusElement = document.querySelector("p.article-status");
			if (!articleStatusElement) return;
			// remove existing spans
			articleStatusElement.querySelectorAll("span").forEach(span => span.remove());
			if (articleData.is_generated) {
				articleStatusElement.innerHTML += `<span class="tag is-success is-normal has-text-weight-bold">Generated</span>`;
			} else if (articleData.is_processing) {
				articleStatusElement.innerHTML += `<span class="tag is-primary is-normal has-text-weight-bold">Processing...</span>`;
			} else if (articleData.is_failed) {
				articleStatusElement.innerHTML += `<span class="tag is-danger is-normal has-text-weight-bold">Failed</span>`;
			} else {
				articleStatusElement.innerHTML += `<span class="tag is-danger is-normal has-text-weight-bold">Unknown</span>`;
			}
		}
	}, [articleData]);

	// ========================================================
	// ------------------- MAIN RENDER CODE -------------------
	// ========================================================
	if (isFetching) return <PageLoading/>;

	if (isError) return <PageLoadingError message={error as unknown as string}/>

	if (!taskData) return <p>No Data Available</p>

	return (
		<div className={"task-log-container"}>
			<div className={"block is-flex is-align-items-center is-justify-content-space-between"}>
				<div className={"is-flex is-align-items-center"}>
					<Link to={pageURL['manageUser'].replace(":userId", userId!)}
						className={"has-text-weight-bold has-text-primary"}>
						<FontAwesomeIcon icon={"chevron-left"}/>&nbsp;&nbsp;Go Back
					</Link>
					<h4 className={"is-size-4 ml-6 secondary-font has-text-weight-bold"}>
						Task Details
					</h4>
				</div>
				<div className={articleData ? "" : "is-hidden"}>
					<Button color={editingLocked ? "danger" : "success"}
						className={"is-small"}
						onClick={() => setEditingLocked(!editingLocked)}>
						{editingLocked ? <><FontAwesomeIcon icon={"lock"} />&nbsp;&nbsp;Editing Locked</> : <><FontAwesomeIcon icon={"lock-open"} />&nbsp;&nbsp;Editing UnLocked</>}
					</Button>
				</div>
			</div>

			<hr className={"dropdown-divider"}/>

			{/* **************************** Article STATUS **************************** */}
			<div className={`block mt-5 ${ (taskData.metadata && taskData.metadata.includes("article")) ? "" : "is-hidden"}`}>
				<Card>
					<div className={"block"}>
						<p className="article-status">
							<FontAwesomeIcon icon={"signal"} />&nbsp;&nbsp;<b>Article Status</b>:&nbsp;&nbsp;
							<span className="tag is-danger is-normal has-text-weight-bold">Deleted</span>
						</p>
					</div>
					<div className={"block mt-6"}>
						<div>
							<Button color={"success"}
								className={"is-small is-outlined"}
								disabled={editingLocked}
								onClick={() => {
									setShowLoader(true);
									setModalText(<>
										<p className={"is-size-5 has-text-primary is-flex is-justify-content-center"}>
											Processing...
										</p>
									</>);
									setActionModalActive(true);
									// check if article is already posted
									if (articleData && articleData.is_posted) {
										setModalText(<>
											<p className={"is-size-5 has-text-primary is-flex is-justify-content-center"}>
												The Article: {taskData.metadata} is already posted, so it cannot be regenerated.
											</p>
										</>);
										setShowLoader(false);
										return;
									}
									const time = new Date().toISOString();
									updateArticleMut.mutate({ article_uid: taskData.metadata, action: "regenerate" }, {
										onSuccess: (response) => {
											const interval = setInterval(() => {
												const current = new Date().toISOString();
												if (new Date(current).getTime() - new Date(time).getTime() > 3000) {
													clearInterval(interval);
													setModalText(<>
														<p className={"is-size-5 has-text-primary is-flex is-justify-content-center"}>
															Article: {taskData.metadata} has been queued for regeneration.
														</p>
													</>);
													setShowLoader(false);
													// take users to the new job task's page after 3 seconds
													setTimeout(() => {
														window.location.href = window.location.href.split("/articlegeneration")[0] + "/" + response.data.job_id;
													}, 3000);
												}
											});
										},
										onError: (error) => {
											const interval = setInterval(() => {
												const current = new Date().toISOString();
												if (new Date(current).getTime() - new Date(time).getTime() > 3000) {
													clearInterval(interval);
													setModalText(<>
														<p className={"is-size-5 has-text-primary is-flex is-justify-content-center"}>
															Failed to queue Article: {taskData.metadata} for regeneration.
														</p>
													</>);
													setShowLoader(false);
												}
											});
										}
									});
								}}>Retry</Button>

							<Button color={"primary"}
								className={"is-small is-outlined ml-4"}
								disabled={editingLocked}
								onClick={() => {
									setShowLoader(true);
									setModalText(<>
										<p className={"is-size-5 has-text-primary is-flex is-justify-content-center"}>
											Processing...
										</p>
									</>);
									setActionModalActive(true);
									// check if article is already failed
									if (articleData && articleData.is_failed) {
										setModalText(<>
											<p className={"is-size-5 has-text-primary is-flex is-justify-content-center"}>
												The Article: {taskData.metadata} is already failed.
											</p>
										</>);
										setShowLoader(false);
										return;
									} else {
										const time = new Date().toISOString();
										updateArticleMut.mutate({ article_uid: taskData.metadata, action: "mark_failed" }, {
											onSuccess: () => {
												const interval = setInterval(() => {
													const current = new Date().toISOString();
													if (new Date(current).getTime() - new Date(time).getTime() > 3000) {
														clearInterval(interval);
														setModalText(<>
															<p className={"is-size-5 has-text-primary is-flex is-justify-content-center"}>
																Article: {taskData.metadata} has been marked as failed.
															</p>
														</>);
														setShowLoader(false);
													}
												});
											},
											onError: (error) => {
												const interval = setInterval(() => {
													const current = new Date().toISOString();
													if (new Date(current).getTime() - new Date(time).getTime() > 3000) {
														clearInterval(interval);
														setModalText(<>
															<p className={"is-size-5 has-text-primary is-flex is-justify-content-center"}>
																Failed to mark Article: {taskData.metadata} as failed.
															</p>
														</>);
														setShowLoader(false);
													}
												}, 1000);
											}
										});
									}
								}}
							>Mark as Failed</Button>

							<Button color={"danger"}
								className={"is-small is-outlined ml-4"}
								disabled={editingLocked}
								onClick={() => {
									setShowLoader(true);
									setModalText(<>
										<p className={"is-size-5 has-text-primary is-flex is-justify-content-center"}>
											Processing...
										</p>
									</>);
									setActionModalActive(true);
									const time = new Date().toISOString();
									updateArticleMut.mutate({ article_uid: taskData.metadata, action: "delete" }, {
										onSuccess: () => {
											setIsArticleDeleted(true);
											// take users to the user page
											const interval = setInterval(() => {
												const current = new Date().toISOString();
												if (new Date(current).getTime() - new Date(time).getTime() > 3000) {
													clearInterval(interval);
													setModalText(<>
														<p className={"is-size-5 has-text-primary is-flex is-justify-content-center"}>
															Article: {taskData.metadata} has been deleted from the database.
														</p>
													</>);
													setShowLoader(false);
												}
											});
										},
										onError: (error) => {
											const interval = setInterval(() => {
												const current = new Date().toISOString();
												if (new Date(current).getTime() - new Date(time).getTime() > 3000) {
													clearInterval(interval);
													setModalText(<>
														<p className={"is-size-5 has-text-primary is-flex is-justify-content-center"}>
															Failed to delete Article: {taskData.metadata} from the database.
														</p>
													</>);
													setShowLoader(false);
												}
											}, 1000);
										}
									});
								}}
							>
								Delete</Button>
						</div>
						<p className={"is-size-7 mt-4"}>(Please don't change status unless necessary)</p>
					</div>
					<div className={"block mt-6"}>
						<p className={"is-size-6 has-text-weight-bold"}>
							Article Generation Cost:&nbsp; <span className={"has-text-primary"}>${articleData ? articleData.cost : "0.00"}</span>
						</p>
					</div>
				</Card>
			</div>

			<div className={"block mt-4"}>
				<Card>
					<div className={"task-info"}>
						<table>
							<tbody>
							<tr>
								<td className={"has-text-weight-bold no-break"}>Job ID / Task ID:</td>
								<td>{jobId}</td>
							</tr>
							<tr>
								<td className={"has-text-weight-bold no-break"}>User Email:</td>
								<td>{taskData.user_email}</td>
							</tr>
							<tr>
								<td className={"has-text-weight-bold no-break"}>User Name:</td>
								<td>{taskData.username}</td>
							</tr>
							<tr>
								<td className={"has-text-weight-bold no-break"}>Created On:</td>
								<td>
									{DateTime
										.fromISO(taskData.created_on)
										.setZone("Asia/Kolkata")
										.toLocaleString(DateTime.DATETIME_MED)}
								</td>
							</tr>
							<tr>
								<td className={"has-text-weight-bold no-break"}>Status:</td>
								<td>{taskData.status}</td>
							</tr>
							<tr>
								<td className={"has-text-weight-bold no-break"}>Retry Attemtps:</td>
								<td>{taskData.retry_attempts}</td>
							</tr>
							<tr>
								<td className={"has-text-weight-bold no-break"}>Metadata:</td>
								<td>{taskData.metadata || "---"}</td>
							</tr>
							<tr>
								<td className={"has-text-weight-bold no-break"}>Keyword:</td>
								<td>{taskData.keyword || "---"}</td>
							</tr>
							</tbody>
						</table>
					</div>
				</Card>
			</div>

			<div className={"block"}>
				<Card>
					<div className={"log-list"}>
						{taskData.logs.length === 0 && <p className={"has-text-centered"}>No Logs Available</p>}
						{/* reversed the logs array to show the latest log at the top */}
						{taskData.logs.slice(0).reverse().map(log => {
							let badgeColorClass = log.type === "info" ? "is-primary" : (log.type === "warning" ? "is-warning" : "is-error")
							return (
								<div className={"log-list--item"} key={log.id}>
									<span className={`tag ${badgeColorClass} tag-width has-text-weight-bold is-capitalized`}>
										{log.type}
									</span>
									<p>
										{/*{log.created_on}*/}
										{DateTime
											.fromISO(log.created_on)
											.setZone("Asia/Kolkata")
											.toLocaleString(DateTime.DATETIME_MED)}
									</p>
									<p>{log.message}</p>
								</div>
							)
						})}
					</div>
				</Card>
			</div>

			{/* **************************** ACTION MODAL **************************** */}
			<AbunModal active={actionModalActive}
				headerText={"Take Action on Article"}
				closeable={true}
				hideModal={() => {
					setActionModalActive(false)
					if (isArticleDeleted) {
						setIsArticleDeleted(false);
						let url = window.location.href;
						window.location.href = url.split("/task-log")[0];
					} else {
						refetch().then();
					}
				}}>
				{showLoader &&
					<Player
						autoplay
						loop
						src="https://assets5.lottiefiles.com/private_files/lf30_tcux3hw6.json"
						style={{ height: '300px', width: '300px' }}
					>
					</Player>
				}
				{modalText}
			</AbunModal>
			<ViewArticle />
		</div>
	)
}
