import "./AutoCoupons.scss";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
    getCoupons,
    addCoupon,
    removeCoupons,
    enableCoupon,
    retryFn,
} from "utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ColumnDef, createColumnHelper, RowData, RowModel } from "@tanstack/react-table";
import AbunTable, { IndeterminateCheckbox } from "components/AbunTable/AbunTable";
import Modal from "components/Modal/Modal";
import Alert from "components/Alert/Alert";

interface Coupon {
    name: string,
    coupon_code: string,
    enabled: boolean,
}

let err_message = "";

// A simple page that shows a list of block website keywords, allows adding new ones one at a time and deleting existing ones in bulk.
export default function AutoCoupons() {
    // ---------------------- NON STATE CONSTANTS ----------------------
    const pageSizes = [15, 50, 100];

    // -------------------------- STATES --------------------------
    const [coupons, setCoupons] = useState<Array<Coupon>>([]);
    const [selectedRows, setSelectedRows] = useState<RowModel<RowData>>();
    const [addCouponToModal, setAddCouponToModal] = useState(false);
    const [couponName, setCouponName] = useState("")
    const [couponID, setCouponID] = useState("")
    const [RemoveActionConfirmationModal, setRemoveActionConfirmationModal] = useState(false);

    // -------------------------- QUERIES --------------------------
    const {
        isFetching,
        isError,
        data,
        refetch,
    } = useQuery({
        queryKey: ['getCoupons'],
        queryFn: getCoupons,
        refetchOnWindowFocus: false,
        retry: retryFn
    });

    // -------------------------- MUTATIONS --------------------------
    const addCouponMutation = useMutation({
        mutationKey: ['addCoupon'],
        mutationFn: addCoupon,
        gcTime: 0,
        onSuccess: (response) => {
            if (!err_message) {
                successAlert.current?.show("Coupons have been added successfully!");
                err_message = "";
            } else {
                errorAlert.current?.show(err_message);
                err_message = "";
            }
            setCoupons(response.data.coupons);
            setAddCouponToModal(false)
        },
        onError: (error) => {
            errorAlert.current?.show(`Failed to save block website coupons: ${error}`);
        },
    });

    const removeCouponsMutation = useMutation({
        mutationKey: ['removeCoupons'],
        mutationFn: removeCoupons,
        gcTime: 0,
        onSuccess: (response) => {
            successAlert.current?.show("Coupons have been removed successfully!");
            setCoupons(response.data.coupons);
        },
        onError: (error) => {
            errorAlert.current?.show(`Failed to remove coupons: ${error}`);
        },
    })

    const enableCouponMutation = useMutation({
        mutationKey: ['enableCoupon'],
        mutationFn: enableCoupon,
        gcTime: 0,
        onSuccess: (response) => {
            successAlert.current?.show("Coupon enabled/disabled successfully!");
            setCoupons(response.data.coupons);
        },
        onError: (error) => {
            errorAlert.current?.show(`Failed to enable/disable coupon: ${error}`);
        },
    })

    // ---------------------- EFFECTS ----------------------
    useEffect(() => {
        if (data) {
            setCoupons(data['data']['coupons']);
        }
    }, [data]);

    // --------------------- REFS ---------------------
    const errorAlert = useRef<any>(null);
    const successAlert = useRef<any>(null);

    // ---------------------- FUNCTIONS ----------------------
    function selectedRowsSetter(rowModel: RowModel<RowData>) {
        setSelectedRows(rowModel);
    }

    function onRemoveCoupons(couponIds: Array<string>) {
        if (couponIds.length < 1) {
            errorAlert.current?.show("Please select at least one coupon to remove.");
            return;
        }
        // on remove multiple selected coupons from the list
        removeCouponsMutation.mutate({
            couponIds: couponIds,
        });
    }

    function onEnableCoupon(couponId: string) {
        if (!couponId) {
            errorAlert.current?.show("Please select at least one coupon to remove.");
            return;
        }
        // on enable coupon
        enableCouponMutation.mutate({
            couponId: couponId,
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!couponName || !couponID) {
            errorAlert.current?.show("Coupon Name and ID required.");
            return;
        }
        addCouponMutation.mutate({
            name: couponName,
            couponId: couponID
        });
    }
    // ---------------------- TABLE COLUMN DEFS ----------------------
    const columnHelper = createColumnHelper<Coupon>();
    const columnDefs: ColumnDef<any, any>[] = [
        columnHelper.accessor((row: Coupon) => row.coupon_code, {
            id: 'checkbox',
            header: ({ table }) => (
                <IndeterminateCheckbox
                    {...{
                        checked: table.getIsAllRowsSelected(),
                        indeterminate: table.getIsSomeRowsSelected(),
                        onChange: table.getToggleAllRowsSelectedHandler(),
                    }}
                />
            ),
            cell: ({ row }) => (
                <IndeterminateCheckbox
                    {...{
                        checked: row.getIsSelected(),
                        disabled: !row.getCanSelect(),
                        indeterminate: row.getIsSomeSelected(),
                        onChange: row.getToggleSelectedHandler(),
                    }}
                    name={"couponSelection"}
                    value={row.original.coupon_code}
                />
            ),
            enableGlobalFilter: true,
        }),
        columnHelper.accessor((row: Coupon) => row.name, {
            id: 'name',
            header: "Name",
            cell: info => info.getValue(),
            enableGlobalFilter: true,
        }),
        columnHelper.accessor((row: Coupon) => row.coupon_code, {
            id: 'coupon_code',
            header: "Coupon ID",
            cell: info => info.getValue(),
            enableGlobalFilter: true,
        }),
        columnHelper.accessor((row: Coupon) => row.enabled, {
            id: 'enabled',
            header: "Enabled",
            cell: props => {
                if (props.row.original.enabled){
                    return (
                        <input type="checkbox" onChange={ e=> {
                            onEnableCoupon(props.row.original.coupon_code)}} checked={true}/>
                    )
                } else {
                    return (
                        <input type="checkbox" onChange={ e=> {
                            onEnableCoupon(props.row.original.coupon_code)}} checked={false}/>
                )
                }
            },
            enableGlobalFilter: true,
        }),
    ]
    
    // ============================================================
    // --------------------- MAIN RENDER CODE ---------------------
    // ============================================================
    if (isFetching) {
        return (
            <p style={{ textAlign: "center", fontSize: "1.3rem" }} className="mt-5">
                Loading Data...<FontAwesomeIcon icon={"spinner"} className={"ml-5"} />
            </p>
        )
    } else if (isError) {
        return (
            <section className="section">
                <div className="container">
                    <div className="box">
                        <h1 className="title has-text-centered">Blocked Website Keywords</h1>
                        <p className="has-text-centered is-size-5">
                            Failed to load data. Please try again later.
                        </p>
                    </div>
                </div>
            </section>
        );
    } else {
        return (
            <section className="section">
                <div className="container">
                    <div className="box">
                        {/* ******************* Add BackLinks Modal ******************* */}
                        <Modal active={addCouponToModal}
                            headerText={"Add Coupon"}
                            closeable={true}
                            hideModal={() => {
                                setAddCouponToModal(false);
                            }
                            }>
                            <div className="tab-content">
                                <form onSubmit={handleSubmit}>
                                    <input type="text" className="input form-control" onChange={(e)=>setCouponName(e.target.value)} placeholder="Enter Coupon Name" />
                                    <input type="text" className="input form-control mt-2" onChange={(e)=>setCouponID(e.target.value)} placeholder="Enter Coupon ID" />
                                    <button type="submit" className={"button is-primary is-fullwidth mt-4"}>Add Coupon</button>
                                </form>
                            </div>
                        </Modal>

                        {/* ******************* Remove confirmation Modal ******************* */}
                        <Modal active={RemoveActionConfirmationModal}
                            headerText={""}
                            closeable={true}
                            hideModal={() => setRemoveActionConfirmationModal(false)}>
                            <h1 className="title has-text-centered">Are you sure you want to remove the selected Coupon(s)?</h1>
                            <div className="buttons is-centered">
                                <button className={"button is-danger is-outlined"}
                                    onClick={() => {
                                        setRemoveActionConfirmationModal(false);
                                        if (selectedRows && selectedRows.rows.length > 0) {
                                            const selectedNames = selectedRows.rows.map(row => {
                                                return (row.original as Coupon).coupon_code
                                            });
                                            onRemoveCoupons(selectedNames);
                                            selectedRows.rows.forEach(row => {
                                                row.toggleSelected();
                                            });
                                        }
                                    }}>
                                    Remove Selected
                                </button>
                                <button className={"button is-primary is-outlined"}
                                    onClick={() => setRemoveActionConfirmationModal(false)}>
                                    Cancel
                                </button>
                            </div>
                        </Modal>

                        <AbunTable tableContentName={"BackLinks"}
                            tableData={coupons}
                            columnDefs={columnDefs}
                            pageSizes={pageSizes}
                            initialPageSize={pageSizes[0]}
                            noDataText={
                                coupons.length === 0 ?
                                    "No coupons available."
                                    : "No coupons found."
                            }
                            searchboxPlaceholderText={"Search coupons..."}
                            rowCheckbox={true}
                            selectedRowsSetter={selectedRowsSetter}
                            buttons={[
                                {
                                    text: "Add Coupon",
                                    type: "primary",
                                    clickHandler: () => setAddCouponToModal(true)
                                },
                                {
                                    text: "Remove All Selected Coupons",
                                    type: "danger",
                                    clickHandler: () => setRemoveActionConfirmationModal(true),
                                    invisible: !selectedRows || selectedRows.rows.length < 1
                                },
                                {
                                    text: "Refresh",
                                    type: "primary",
                                    clickHandler: () => refetch()
                                }
                            ]} />
                    </div>
                </div>
                <Alert type={"danger"} ref={errorAlert} />
                <Alert type={"success"} ref={successAlert} />
            </section>
        );
    }
}
