import Sidebar from "components/Sidebar/Sidebar";
import {Outlet} from "react-router-dom";

export default function LoggedInLayout() {
	return (
		<div className={"main-container"}>
			<Sidebar/>
			<div className={"workspace-container"}>
				<Outlet/>
			</div>
		</div>
	)
}
