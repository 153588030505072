import "./RadioInput.scss";

interface RadioProps {
	name: string
	radioElements: string[][]  // text first, value second
	current: string
	onChange: (value: string) => void;

	className?: string
	id?: string
}

export default function RadioInput(props: RadioProps) {
	return (
		<div className={`is-flex ${props.className}`} id={props.id}>
			{props.radioElements.map(element => (
				<div className="control radio-input" key={element[1]}>
					<label className="radio">
						<input type="radio"
									 name={props.name}
									 onChange={() => props.onChange(element[1])}
									 checked={props.current === element[1]}/>
						&nbsp;&nbsp;{element[0]}
					</label>
				</div>
			))}
		</div>
	)
}
