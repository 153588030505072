import "./ViewKeywords.scss";
import {useEffect, useState} from "react";
import {ColumnDef, createColumnHelper} from "@tanstack/react-table";
import { useParams, NavLink } from "react-router-dom";

import Input from "components/Input/Input";
import Table from "components/Table/Table";
import AbunButton from "components/AbunButton/AbunButton";
import {useQuery} from "@tanstack/react-query";
import {getKeywordProjectData, retryFn} from "../../utils/api";
import PageLoading from "../../components/PageLoading/PageLoading";
import PageLoadingError from "../../components/PageLoadingError/PageLoadingError";

interface KeywordsProjectTableProps {
	keyword: string;
	keywordHash: string;
	keywordTraffic: number;
	difficultyScore: string;
	titlesGenerated: boolean;
	mostRecentArtTitleTimestamp: string;
}

export default function ViewKeywords() {
    // --------------------- REACT ROUTER PARAMS ---------------------
	let {projectID} = useParams();

	// ---------------------- NON STATE CONSTANTS ----------------------
	const pageSizes = [100, 500, 1000];

	// ---------------------- STATES ----------------------
	const [keywordsData, setKeywordData] = useState<KeywordsProjectTableProps[]>([]);
	const [searchBoxFilter, setSearchBoxFilter] = useState('');

	// ---------------------- QUERIES ----------------------
	const {
		isFetching,
		isError,
		error,
		data
	} = useQuery({
		queryKey: ['getKeywordProjectData', projectID],
		queryFn: () => getKeywordProjectData(projectID!),
		refetchOnWindowFocus: false,
		retry: retryFn,
	});

	// ---------------------- EFFECTS ----------------------
	useEffect(() => {
		if (data) {
			setKeywordData(data['data']);
		}
	}, [data])


	// ---------------------- TABLE COLUMN DEFS ----------------------
	const columnHelper = createColumnHelper<KeywordsProjectTableProps>();
	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: KeywordsProjectTableProps) => row.keyword, {
			id: 'keyword',
			header: "Keyword",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: KeywordsProjectTableProps) => row.keywordHash, {
			id: 'keywordHash',
			header: "Keyword Hash",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: KeywordsProjectTableProps) => row.keywordTraffic, {
			id: 'keywordTraffic',
			header: "Keyword Traffic",
			cell: info => info.getValue(),
			enableGlobalFilter: false,
		}),
		columnHelper.display({
			id: 'difficultyScore',
			header: "Difficulty",
			cell: props => {
				if (props.row.original.difficultyScore) {
					const difficultyScore = parseFloat(props.row.original.difficultyScore);
					let difficulty = 'Easy'
					let btn_color = 'success'

					if (difficultyScore > 0.3 && difficultyScore < 0.6) {
						difficulty = 'Moderate'
						btn_color = 'warning'
					}
					else if (difficultyScore > 0.6) {
						difficulty = 'HIGH'
						btn_color = 'danger'
					}
					return (
						<button type="button" className={`button is-small more-rounded-borders ${btn_color !== 'info' ? `is-${btn_color}` : ''} custom-btn`} style={btn_color === 'info' ? { backgroundColor: 'yellow', color: 'black', cursor: "default" } : { cursor: "default" }}>{difficulty}</button>
					);
				}
			},
		}),
		columnHelper.display({
			id: 'action',
			header: "Action",
			cell: cellProps => {
				return (
					<div className="">
						{
							cellProps.row.original.titlesGenerated ?
								<NavLink to={`/view-titles/${cellProps.row.original.keywordHash}/`} className={`button is-small more-rounded-borders is-primary custom-btn`}>
									View Titles
								</NavLink> :
								<AbunButton type={"primary"}
								className={"is-outlined is-small comp-research-table-button"}
								disabled={true}
								clickHandler={() => {
									console.log("clicked")
								}}>
								View Titles
							</AbunButton>
						}
					</div>
				);
			},
		}),
		
	]

	// ==============================================================
	// ---------------------- MAIN RENDER CODE ----------------------
	// ==============================================================
	if (isFetching) return <PageLoading/>;

	if (isError) return <PageLoadingError message={error as unknown as string}/>

	return (
		<div className={"keyword-container"}>
			<div className={"table-controls"}>
				{/* -------------------------- SEARCH BOX -------------------------- */}
				<Input type={"text"}
							 className={"table-searchbox"}
							 placeholder={"Search for Keywords..."}
							 value={searchBoxFilter ?? ''}
							 onChange={(e) => {
								 setSearchBoxFilter(String(e.target.value));
							 }}/>
			</div>
			<Table tableContentName={"Keywords"}
						 tableData={keywordsData}
						 columnDefs={columnDefs}
						 pageSizes={pageSizes}
						 initialPageSize={pageSizes[0]}
						 noDataText={"No Admins Found"}
						 searchBoxValue={searchBoxFilter}/>
		</div>
	)
}
