import "./404.scss";
import {Link} from "react-router-dom";
import {pageURL} from "../routes";

export default function PageNotFound() {
	return (
		<div className={"page-not-found-container"}>
			<h2 className={"is-size-2 secondary-font has-text-centered"}>
				Hmmm... There's No Such Page 🤔
			</h2>
			<p className={"is-size-5 has-text-centered has-text-grey-darker mt-2"}>
				The page or feature you were trying to access might not be complete yet <br/> or you have entered a non-existent URL.
			</p>
			<p className={"is-size-5 has-text-centered mt-5 has-text-danger"}>
				ERROR: 404 Page Not Found
			</p>
			<p className={"mt-6"}>Let's get back to <Link to={pageURL['dashboard']}>Dashboard</Link></p>
		</div>
	)
}
