import "./ViewTitles.scss";
import {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {loadArticleTitlesForKeywordQuery, retryFn} from "utils/api";
import Card from "components/Card/Card";
import PageLoading from "components/PageLoading/PageLoading";
import PageLoadingError from "components/PageLoadingError/PageLoadingError";
import SerperResults from "components/SerperResult/SerperResults";
import Titles from "./Titles"

interface TitleData {
	keyword: string
	title_data: TitleTableData[];
	serper_data: SerperTitle[];
}

interface TitleTableData {
	articleUID: string;
	articleTitle: string;
	keyword: string;
	keywordHash: string;
	keywordTraffic: number;
	wordCount: number;
	isProcessing: boolean;
	isGenerated: boolean;
	isPosted: boolean;
	isFailed: boolean;
	isArchived: boolean;
	postedTo: string,
}

interface SerperTitle {
	url: string;
    rank: number;
    title: string;
    images: number;
    DA_score: number,
    word_count: number,
    description: string;
    keyword_count: number,
    external_links: number,
    internal_links: number
}

export default function ViewTitles() {
    // --------------------- REACT ROUTER PARAMS ---------------------
	let {keywordHash} = useParams();

	// ---------------------- STATES ----------------------
	const [titlesData, setTitleData] = useState<TitleData>({
		keyword: "",
		title_data: [],
		serper_data: []
	});

	// ---------------------- QUERIES ----------------------
	const {
		isFetching,
		isError,
		error,
		data
	} = useQuery({
		queryKey: ['loadArticleTitlesForKeywordQuery', keywordHash],
		queryFn: () => loadArticleTitlesForKeywordQuery(keywordHash!),
		refetchOnWindowFocus: false,
		retry: retryFn,
	});

	// ---------------------- EFFECTS ----------------------
	useEffect(() => {
		if (data) {
			setTitleData(data['data']);
		}
	}, [data])

	console.log(titlesData)
	// ==============================================================
	// ---------------------- MAIN RENDER CODE ----------------------
	// ==============================================================
	if (isFetching) return <PageLoading/>;

	if (isError) return <PageLoadingError message={error as unknown as string}/>

	return (
		<>
			{titlesData.serper_data['result'] && <>
					<Card>
						<h3 className={"is-size-3 has-text-centered"}>Serper Data ({titlesData.keyword})</h3>
						<SerperResults results={titlesData.serper_data['result']}/>
					</Card>
			</>}
			{titlesData.title_data.length > 0 && <>
					<Card className="mt-3">
						<h3 className={"is-size-3 has-text-centered"}>Titles</h3>
						<Titles results={titlesData.title_data}/>
					</Card>
			</>}
		</>
		
	)
}
