'use client'

import "./Button.scss";

import {CSSProperties, ReactNode} from "react";

interface ButtonProps {
	children: ReactNode
	color: "primary" | "warning" | "success" | "danger" | "info"
	onClick: () => void

	className?: string
	style?: CSSProperties
	disabled?: boolean
}

export default function Button(props: ButtonProps) {
	return (
		<button type={"button"}
						className={`button is-${props.color} ${props.className || ""}`}
						onClick={props.onClick}
						style={props.style}
						disabled={props.disabled}>
			{props.children}
		</button>
	)
}
