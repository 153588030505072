import "./WebsiteContentPlan.scss";
import {Link, useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {pageURL} from "pages/routes";
import Card from "components/Card/Card";
import {useEffect, useRef, useState} from "react";
import Button from "components/Button/Button";
import {useMutation, useQuery} from "@tanstack/react-query";
import {changeContentPlanStatus, getContentPlanData, retryFailedContentPlan, retryFn} from "../../utils/api";
import PageLoading from "../../components/PageLoading/PageLoading";
import PageLoadingError from "../../components/PageLoadingError/PageLoadingError";
import {DateTime} from "luxon";
import Alert from "../../components/Alert/Alert";

interface ContentPlanData {
	status: ContentPlanStatus
	started_on: string
	website_name: string
	website_domain: string
	website_logo_url: string
	website_title: string
	website_description: string
	website_icp: string
	website_industry: string
	website_competitors: string[]
	performance_chart_url: string
	user_keywords: string[]
	website_stats: WebsiteStats[]
}

interface WebsiteStats {
	name: string
	value: string | number
}

type ContentPlanStatus = "done" | "processing" | "failed";

export default function WebsiteContentPlan() {
	// --------------------- REACT ROUTER PARAMS ---------------------
	let {userId, websiteId} = useParams();

	// --------------------- STATES ---------------------
	const [
		contentPlanData,
		setContentPlanData
	] = useState<ContentPlanData | null>(null);

	const [
		editingLocked,
		setEditingLocked
	] = useState(true);

	// --------------------- REFS ---------------------
	const errorAlert = useRef<any>(null);

	// --------------------- QUERIES ---------------------
	const {
		isFetching,
		isError,
		error,
		data,
		refetch
	} = useQuery({
		queryKey: ['getContentPlanData', userId, websiteId],
		queryFn: () => getContentPlanData({userId: userId!, websiteId: websiteId!}),
		refetchOnWindowFocus: false,
		retry: retryFn
	});

	// --------------------- MUTATIONS ---------------------
	const changeStatusMut = useMutation({
		mutationKey: ['changeContentPlanStatus'],
		mutationFn: changeContentPlanStatus,
		gcTime: 0,
		onError: (error) => {
			errorAlert.current?.show(`Failed to change content plan status: ${error}`);
		}
	});

	const retryFailedContentPlanMut = useMutation({
		mutationKey: ['retryFailedContentPlan'],
		mutationFn: retryFailedContentPlan,
		gcTime: 0,
		onError: (error) => {
			errorAlert.current?.show(`Failed to retry content plan: ${error}`);
		},
		onSuccess: () => {
			refetch().then();
		}
	})

	// --------------------- EFFECTS ---------------------
	useEffect(() => {
		if (data) {
			console.log(data['data']);
			setContentPlanData(data['data']);
		}
	}, [data]);

	// --------------------- FUNCTIONS ---------------------
	function renderContentPlanStatus() {
		switch (contentPlanData?.status) {
			case "done":
				return <span className="tag is-success is-normal has-text-weight-bold">Completed</span>

			case "processing":
				return <span className="tag is-primary is-normal has-text-weight-bold">Processing...</span>

			case "failed":
				return <span className="tag is-danger is-normal has-text-weight-bold">Failed</span>

			default:
				return <span className="tag is-danger is-normal has-text-weight-bold">Unknown</span>
		}
	}

	// ============================================================
	// --------------------- MAIN RENDER CODE ---------------------
	// ============================================================
	if (isFetching) return <PageLoading/>;

	if (isError) return <PageLoadingError message={error as unknown as string}/>

	if (!contentPlanData) return <p>No Data</p>

	return (
		<div className={"website-content-plan-container"}>
			<div className={"block is-flex is-align-items-center is-justify-content-space-between"}>
				<div className={"is-flex is-align-items-center"}>
					<Link to={pageURL['manageUser'].replace(":userId", userId!)}
								className={"has-text-weight-bold has-text-primary"}>
						<FontAwesomeIcon icon={"chevron-left"}/>&nbsp;&nbsp;Go Back
					</Link>
					<h4 className={"is-size-4 ml-6 secondary-font has-text-weight-bold"}>
						Content Plan Details
					</h4>
				</div>
				<div>
					<Button color={editingLocked ? "danger" : "success"}
									className={"is-small"}
									onClick={() => setEditingLocked(!editingLocked)}>
						{editingLocked ? <><FontAwesomeIcon icon={"lock"}/>&nbsp;&nbsp;Editing Locked</> : <><FontAwesomeIcon icon={"lock-open"}/>&nbsp;&nbsp;Editing UnLocked</>}
					</Button>
				</div>
			</div>

			<hr className={"dropdown-divider"}/>

			{/* **************************** CONTENT PLAN STATUS **************************** */}
			<div className={"block mt-6"}>
				<Card>
					<div className={"block"}>
						<p>
							<FontAwesomeIcon icon={"signal"}/>&nbsp;&nbsp;<b>Content Plan
							Status</b>:&nbsp;&nbsp;{renderContentPlanStatus()}
						</p>
						<p>
							<FontAwesomeIcon icon={"clock"}/>
							&nbsp;&nbsp;
							<b>Started On</b>: {
							DateTime
								.fromISO(contentPlanData.started_on)
								.setZone("Asia/Kolkata")
								.toLocaleString(DateTime.DATETIME_MED)
						}
						</p>
					</div>
					<div className={"block mt-6"}>
						<div>
							<Button color={"success"}
											className={"is-small is-outlined"}
											disabled={editingLocked}
											onClick={() => {
								changeStatusMut.mutate({
									user_id: parseInt(userId!),
									website_id: parseInt(websiteId!),
									new_status: "done"
								}, {
									onSuccess: () => {
										refetch().then();
									}
								});
							}}>Mark as Done</Button>

							<Button color={"primary"}
											className={"is-small is-outlined ml-4"}
											disabled={editingLocked}
											onClick={() => {
								changeStatusMut.mutate({
									user_id: parseInt(userId!),
									website_id: parseInt(websiteId!),
									new_status: "processing"
								}, {
									onSuccess: () => {
										setContentPlanData({...contentPlanData, status: "processing"});
									}
								});
							}}>Mark as Processing</Button>

							<Button color={"danger"}
											className={"is-small is-outlined ml-4"}
											disabled={editingLocked}
											onClick={() => {
								changeStatusMut.mutate({
									user_id: parseInt(userId!),
									website_id: parseInt(websiteId!),
									new_status: "failed"
								}, {
									onSuccess: () => {
										setContentPlanData({...contentPlanData, status: "failed"});
									}
								});
							}}>Mark as Failed</Button>
						</div>
						<p className={"is-size-7 mt-4"}>(Please don't change status unless necessary)</p>
					</div>
				</Card>
			</div>

			{contentPlanData.status === "failed" &&
          <div className={"is-flex is-justify-content-center is-align-items-center mt-6"}>
              <Button color={"primary"}
                      disabled={retryFailedContentPlanMut.isPending}
                      onClick={() => {
												retryFailedContentPlanMut.mutate({
													user_id: parseInt(userId!),
													website_id: parseInt(websiteId!),
												});
											}}>
								{retryFailedContentPlanMut.isPending ? "Sending Request..." : "Retry Content Plan"}
              </Button>
          </div>}

			{contentPlanData.status === "done" && <>
				{/* **************************** WEBSITE RELATED INFO **************************** */}
          <div className={"block mt-4"}>
              <Card>
                  <div className={"website-info"}>
                      <table>
                          <tbody>
                          <tr>
                              <td className={"has-text-weight-bold no-break"}>Website Name</td>
                              <td>{contentPlanData.website_name}</td>
                          </tr>
                          <tr>
                              <td className={"has-text-weight-bold no-break"}>Website Domain</td>
                              <td>
                                  <Link to={`https://${contentPlanData.website_domain}`}
                                        target={"_blank"}
                                        rel={"noreferrer"}>
																		{contentPlanData.website_domain}
                                  </Link>
                              </td>
                          </tr>
                          <tr>
                              <td className={"has-text-weight-bold no-break"}>Logo URL</td>
                              <td>
                                  <Link to={contentPlanData.website_logo_url}
                                        target={"_blank"}
                                        rel={"noreferrer"}>
																		{contentPlanData.website_logo_url}
                                  </Link>
                              </td>
                          </tr>
                          <tr>
                              <td className={"has-text-weight-bold no-break"}>Website Title</td>
                              <td>{contentPlanData.website_title || "---"}</td>
                          </tr>
                          <tr>
                              <td className={"has-text-weight-bold no-break"}>Website Description</td>
                              <td>{contentPlanData.website_description || "---"}</td>
                          </tr>
                          <tr>
                              <td className={"has-text-weight-bold no-break"}>Industry</td>
                              <td>{contentPlanData.website_industry || "---"}</td>
                          </tr>
													<tr>
                              <td className={"has-text-weight-bold no-break"}>ICP</td>
                              <td>{contentPlanData.website_icp || "---"}</td>
                          </tr>
                          <tr>
                              <td className={"has-text-weight-bold no-break"}>Competitors</td>
                              <td>{contentPlanData.website_competitors.join(", ") || "---"}</td>
                          </tr>
                          <tr>
                              <td className={"has-text-weight-bold no-break"}>User Keywords</td>
                              <td>{contentPlanData.user_keywords.join(", ") || "---"}</td>
                          </tr>
                          <tr>
                              <td className={"has-text-weight-bold no-break"}>Performance Chart</td>
                              <td>
																{
																	contentPlanData.performance_chart_url ?
																		<Link to={contentPlanData.performance_chart_url}
																					target={"_blank"}
																					rel={"noreferrer"}>
																			{contentPlanData.performance_chart_url}
																		</Link> :
																		"---"
																}
                              </td>
                          </tr>
                          </tbody>
                      </table>
                  </div>
              </Card>
          </div>

				{/* **************************** VARIOUS STATS **************************** */}
          <div className={"block mt-4"}>
              <div className={"content-plan-cards-container"}>
								{contentPlanData.website_stats.map(stat => (
									<Card>
										<h5
											className={"is-size-5 secondary-font has-text-warning has-text-weight-bold mb-2"}>{stat.name}</h5>
										<p className={"is-size-4 mb-0"}>{stat.value || "NA"}</p>
									</Card>
								))}
              </div>
          </div>
      </>}
			<Alert type={"danger"} ref={errorAlert}/>
		</div>
	)
}
