import Card from "components/Card/Card";
import Button from "components/Button/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RadioInput from "components/RadioInput/RadioInput";
import {MutableRefObject, useState} from "react";
import {useMutation} from "@tanstack/react-query";
import {deleteWebsite, saveWebsiteData} from "../../utils/api";

interface WebsiteProps {
	userId: number
	websiteId: number
	name: string
	domain: string
	logo: string
	imageSource: string

	refetchUserData: () => void

	errorAlert: MutableRefObject<any>
	successAlert: MutableRefObject<any>

	className?: string
}

export default function ManageUserWebsite(props: WebsiteProps) {
	// ----------------------- NON STATE CONSTANTS -----------------------
	const imageSourceValues = [
		["No Image", "no_image"],
		["Unsplash", "unsplash"],
		["Google", "google"],
		["AI Image Generation", "ai_image_generation"]
	]

	// ----------------------- STATES -----------------------
	const [deleteProtection, setDeleteProtection] = useState(true);
	const [
		websiteImageSource,
		setWebsiteImageSource
	] = useState(props.imageSource);

	// ----------------------- MUTATION -----------------------
	const saveWebsiteDataMut = useMutation({
		mutationKey: ['saveWebsiteData'],
		mutationFn: saveWebsiteData,
		gcTime: 0,
		onSuccess: () => {
			props.successAlert.current?.show("All website data saved successfully");
		},
		onError: (error) => {
			props.errorAlert.current?.show(`Failed to save website data: ${error}`);
		}
	});

	const deleteWebsiteMut = useMutation({
		mutationKey: ['deleteWebsite'],
		mutationFn: deleteWebsite,
		gcTime: 0,
		onSuccess: () => {
			props.refetchUserData();
		},
		onError: (error) => {
			props.errorAlert.current?.show(`Failed to delete website: ${error}`);
		}
	});

	// ====================================================================
	// ----------------------- MAIN RENDER FUNCTION -----------------------
	// ====================================================================
	return (
		<>
				<Card className={props.className}>
					<div className={"is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center"}>
						<div className={"is-flex is-flex-direction-row is-justify-content-center is-align-items-center"}>
							<img src={props.logo} alt="website logo" style={{width: "auto", height: "64px", borderRadius: "100%"}}/>
							<h3 className={"is-size-3 has-text-primary mb-0 mt-0 ml-4"}>
								{props.name}
							</h3>
						</div>
						<div>
							<Button color={"success"} className={"is-small"} onClick={() => {
								saveWebsiteDataMut.mutate({
									user_id: props.userId,
									domain: props.domain,
									image_source: websiteImageSource,
								});
							}}>
								<FontAwesomeIcon icon={"floppy-disk"}/>&nbsp;&nbsp;Save Changes
							</Button>
							<Button color={deleteProtection ? "danger" : "primary"}
											className={"is-small ml-4"}
											onClick={() => setDeleteProtection(!deleteProtection)}>
								<FontAwesomeIcon icon={"lock"}/>
								&nbsp;&nbsp;{deleteProtection ? "Disable Delete Protection" : "Enable Delete Protection"}
							</Button>
						</div>
					</div>
					<hr className={"dropdown-divider mt-4"}/>
					<div className={"is-flex is-flex-direction-column mt-5"}>
						{/* ********************* WEBSITE DETAILS ********************* */}
						<div className={"block"}>
							<p className={"is-size-5"}>
								<FontAwesomeIcon icon={"globe"}/>
								&nbsp;
								Website Domain: <a target={"_blank"} rel={"noreferrer"}
																	 href={"https://" + props.domain}>{props.domain}</a>
							</p>
						</div>
						{/* ********************* IMAGE SOURCE ********************* */}
						<div className={"block mt-4"}>
							<label htmlFor={"image-source"} className={"label"}>Article Generation Image Source:</label>
							<RadioInput name={`image-source-${props.domain}`}
													id={"image-source"}
													radioElements={imageSourceValues}
													current={websiteImageSource}
													onChange={(value) => setWebsiteImageSource(value)}/>
						</div>
						{/* ********************* DELETE WEBSITE ********************* */}
						<hr className={"dropdown-divider mt-5"}/>
						<div className={"mt-6"}>
							<p className={"has-text-danger is-size-6"}>
								<b>IMPORTANT:</b> This will delete the website from user's account <b>immediately</b>. This action
								cannot
								be
								undone!
							</p>
							<Button color={"danger"}
											className={"is-outlined"}
											disabled={deleteProtection || deleteWebsiteMut.isPending}
											onClick={() => {
												deleteWebsiteMut.mutate({
													user_id: props.userId,
													domain: props.domain
												});
											}}>
								<FontAwesomeIcon icon={"trash-can"}/>
								&nbsp;&nbsp;
								{deleteWebsiteMut.isPending ? "Deleting..." : <>Delete Website&nbsp;<b>{props.domain}</b></>}
							</Button>
						</div>
					</div>
				</Card>
		</>
	)
}
