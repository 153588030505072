import { useState } from "react";
import {ColumnDef, createColumnHelper} from "@tanstack/react-table";
import {NavLink} from "react-router-dom";
import Table from "components/Table/Table";
import Input from "components/Input/Input";

interface SerperTitle {
	url: string;
    rank: number;
    title: string;
    images: number;
    DA_score: number,
    word_count: number,
    description: string;
    keyword_count: number,
    external_links: number,
    internal_links: number
}

export default function SerperResults(props: {results: Array<SerperTitle>}) {
	// ---------------------- NON STATE CONSTANTS ----------------------
	const pageSizes = [10, 50, 150, 250, 500];

	// ---------------------- STATES ----------------------
	const [searchBoxFilter, setSearchBoxFilter] = useState('');

	// ---------------------- TABLE COLUMN DEFS ----------------------
	const columnHelper = createColumnHelper<SerperTitle>();
	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: SerperTitle) => row.title, {
			id: 'title',
			header: "Article Title",
			cell: props => {
				
				return (
					<NavLink to={`${props.row.original.url}`} target="_blank">
						{props.row.original.title}
					</NavLink>
				)
			
            },
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: SerperTitle) => row.rank, {
			id: 'rank',
			header: "Rank",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
        columnHelper.accessor((row: SerperTitle) => row.DA_score, {
			id: 'DA_score',
			header: "DA Score",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: SerperTitle) => row.images, {
			id: 'images',
			header: "Images",
			cell: props => {
                return (
                    `${props.row.original.images}`
                )
            },
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: SerperTitle) => row.word_count, {
			id: 'word_count',
			header: "Word Count",
			cell: info => {
                if (info.getValue()){
                    return info.getValue()
                } else {
                    return "---"
                }
            },
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: SerperTitle) => row.external_links, {
			id: 'external_links',
			header: "External Links",
			cell: info => {
                    return info.getValue()
            },
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: SerperTitle) => row.internal_links, {
			id: 'internal_links',
			header: "Internal Links",
			cell: info => {
                    return info.getValue()
            },
			enableGlobalFilter: false,
		}),
	]

	// ==============================================================
	// ---------------------- MAIN RENDER CODE ----------------------
	// ==============================================================
	return (
		<div className={"all-users-container"}>
			<div className={"table-controls"}>
				{/* -------------------------- SEARCH BOX -------------------------- */}
				<Input type={"text"}
							 className={"table-searchbox"}
							 placeholder={"Search for Serper Articles..."}
							 value={searchBoxFilter ?? ''}
							 onChange={(e) => {
								 setSearchBoxFilter(String(e.target.value));
							 }}/>
			</div>
			<Table tableContentName={"Serper Articles"}
						 tableData={props.results}
						 columnDefs={columnDefs}
						 pageSizes={pageSizes}
						 initialPageSize={pageSizes[0]}
						 noDataText={"No Data Found"}
						 searchBoxValue={searchBoxFilter}/>
		</div>
	)
}
