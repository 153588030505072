import "./Error.scss";
import {isRouteErrorResponse, useRouteError} from "react-router-dom";

export default function Error() {
	const error = useRouteError() as Error;
	let errorMessage: string;

	if (isRouteErrorResponse(error)) {
    // error is type `ErrorResponse`
    errorMessage = error.statusText;

	} else {
    errorMessage = error.message;

	}

	return (
		<div className={"error-page-container"}>
			<h2 className={"is-size-2 has-text-centered"}>Oops! Something Went Wrong 😢</h2>
			<p className={"is-size-5 mb-4"}>Please try refreshing the page couple of times. If it doesn't work let me know.</p>
			<p className={"is-size-5 has-text-danger"}>ERROR: {errorMessage}</p>
		</div>
	)
}
