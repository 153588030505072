import "./Admins.scss";
import {useEffect, useState} from "react";
import {ColumnDef, createColumnHelper} from "@tanstack/react-table";
import {DateTime} from "luxon";
import Input from "components/Input/Input";
import Table from "components/Table/Table";
import {useQuery} from "@tanstack/react-query";
import {getAllAdminData, retryFn} from "../../utils/api";
import PageLoading from "../../components/PageLoading/PageLoading";
import PageLoadingError from "../../components/PageLoadingError/PageLoadingError";

interface AdminData {
	id: number
	username: string
	email: string
	date_joined: string
}

export default function Admins() {
	// ---------------------- NON STATE CONSTANTS ----------------------
	const pageSizes = [100, 500, 1000];

	// ---------------------- STATES ----------------------
	const [adminData, setAdminData] = useState<AdminData[]>([]);
	const [searchBoxFilter, setSearchBoxFilter] = useState('');

	// ---------------------- QUERIES ----------------------
	const {
		isFetching,
		isError,
		error,
		data
	} = useQuery({
		queryKey: ['fetchAllAdmins'],
		queryFn: getAllAdminData,
		refetchOnWindowFocus: false,
		retry: retryFn,
	});

	// ---------------------- EFFECTS ----------------------
	useEffect(() => {
		if (data) {
			setAdminData(data['data']);
		}
	}, [data])


	// ---------------------- TABLE COLUMN DEFS ----------------------
	const columnHelper = createColumnHelper<AdminData>();
	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: AdminData) => row.email, {
			id: 'email_id',
			header: "Email ID",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: AdminData) => row.username, {
			id: 'username',
			header: "Username",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: AdminData) => row.date_joined, {
			id: 'date_joined',
			header: "Date Joined (IST)",
			cell: info => DateTime
				.fromISO(info.getValue())
				.setZone("Asia/Kolkata")
				.toLocaleString(DateTime.DATETIME_MED),
			enableGlobalFilter: false,
		}),
	]

	// ==============================================================
	// ---------------------- MAIN RENDER CODE ----------------------
	// ==============================================================
	if (isFetching) return <PageLoading/>;

	if (isError) return <PageLoadingError message={error as unknown as string}/>

	return (
		<div className={"admins-container"}>
			<div className={"table-controls"}>
				{/* -------------------------- SEARCH BOX -------------------------- */}
				<Input type={"text"}
							 className={"table-searchbox"}
							 placeholder={"Search for Users..."}
							 value={searchBoxFilter ?? ''}
							 onChange={(e) => {
								 setSearchBoxFilter(String(e.target.value));
							 }}/>
			</div>
			<Table tableContentName={"Users"}
						 tableData={adminData}
						 columnDefs={columnDefs}
						 pageSizes={pageSizes}
						 initialPageSize={pageSizes[0]}
						 noDataText={"No Admins Found"}
						 searchBoxValue={searchBoxFilter}/>
		</div>
	)
}
